/* input type="number" remove arrows */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* language dropdown */
.dropdown-toggle::after {
  display: none !important;
}
.dropdown {
  padding: 2px;
  display: inline-block;
}
.btn-transparent {
  padding: 5px 5px 5px 10px !important;
}
.dropdown-menu {
  min-width: unset;
}
.dropdown-item{
  font-size: 13px !important;
  font-family: 'FiraGO';
  color: #555;
}
.slider-pb-55 > .react-multi-carousel-list{
  padding-bottom: 55px;
}
.slider-pb-55 .box_list {
  box-shadow: none;
  min-height: unset !important;
}
.user-select-0 {
  user-select: none !important;
  pointer-events: none !important;
}

/* slider dots responsive */
/* @media screen and (min-width: 320px) and (max-width: 767px) {
  .react-multi-carousel-dot-list > .active {
    width: 11px !important;
    height: 25px !important;
  }
  .react-multi-carousel-dot-list > .inactive {
    width: 11px !important;
    height: 25px !important;
  }
} */

/* remove body spacing when opening select */
body {
  padding-right: unset !important;
  overflow: unset !important;
}

/* dashboard popular cards */
/* .react-multi-carousel-item + .popular-tour-card {
} */

/* .react-multi-carousel-item + .react-multi-carousel-item--active + .popular-tour-card{
  background-color: red !important;
} */
/* .react-multi-carousel-item--active:last-child {
    background-color: green;
} */

/* .popular-tour-carousel-container ul .react-multi-carousel-item--active:nth-child(2) {
    background-color: red;
    transform: scale(1.2);
}
.popular-tour-carousel-container ul li:last-child {
    background-color: green;
} */
/* dashboard popular cards */

/* dropdown content */
.MuiPaper-elevation {
  border-radius: 10px !important;
  background-color: #ffffff;
  margin-top: 10px;
}
.MuiMenu-list {
  padding: 0px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.MuiMenuItem-gutters {
  line-height: 30px !important;
  padding: 0px !important;
  padding-left: 24px !important;
  font-size: 14px !important;
  font-family: "Ubuntu Regular" !important;
  color: #555 !important;
  background-color: transparent !important;
}
.Mui-selected {
  background-color: transparent !important;
  color: #555 !important;
}
.MuiMenuItem-gutters:hover {
  background-color: transparent !important;
  color: #555 !important;
}
/* dropdown content */

/* dropdown input head */
.MuiInputBase-formControl {
  border-radius: 10px !important;
  border-color: #555 !important;
}
.MuiSelect-select {
  border-radius: 10px !important;
  padding: 16px 40px 16px 20px !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #555 !important;
}
.Mui-focused {
  color: #555 !important;
}
.MuiTextField-root {
  background-color: transparent;
}
.MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #555 ;
}
/* dropdown input head */
.MuiInputLabel-shrink {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
legend {
  margin-left: 4px !important;
}
.MuiSelect-iconOutlined {
  margin-right: 11px;
  font-size: 11px;
}

/* home form input wrapper styles */
.home-form-input .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}
.home-form-input .MuiInputLabel-outlined,
.home-form-input .MuiSelect-icon,
.home-form-input .MuiOutlinedInput-input
 {
  color: white !important;
}

/* for input search icon remove */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
/* for input search icon remove */

/* tab bottom line color */
.MuiTabs-indicator {
  background-color: #222b2e !important;
}
.MuiTouchRipple-root {
  display: none !important;
}