@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5a87a8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a87a8;
}

/* scroll bar */

/* hide html editor warning message */
.tox-notification.tox-notification--in.tox-notification--warning{
  display: none !important;
}
.tox-editor-container ~ .tox-statusbar {
  display: none !important;
}