/*
Theme Name: Panagea v.2.3
Theme URI: http://www.ansonika.com/panagea/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents] 

1. SITE STRUCTURE and TYPOGRAPHY
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Home
- 2.2 About
- 2.3 Listing
- 2.4 Detail page
- 2.5 Login/register
- 2.6 Map styles
- 2.7 Contacts
- 2.8 404 page 
- 2.9 Cart section
- 2.10 Media Gallery
- 2.11 Faq
- 2.12 Adventure
- 2.13 Home AirBnb
- 2.14 Half screen map
- 2.15 Autocomplete Address Search
- 2.16 OpenStreet Map
- 2.17 Parallax Video Background
- 2.18 Other Headers

3. COMMON
- 3.1 Misc
- 3.2 Accordion
- 3.3 List
- 3.4 Spacing
- 3.5 Block reveal
- 3.6 Cookie bar
- 3.7 Sing In Modal
- 3.8 Input Switcher
- 3.9 Datepicker
- 3.10 Modal Generic
- 3.11 Others pricing tables
- 3.12 Styles Switcher

/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/

@import 'variables';
@import 'mixin';

/* MAIN NAVIGATION */
/* All styles for screen size over 992px*/
@media only screen and (min-width: 992px) {
	nav#menu.main-menu {
		display: block !important;
	}
	#mm-menu.main-menu {
		display: none !important;
	}
	header .btn_mobile {
		display: none !important;
	}
	/* Menu */
	.main-menu {
		position: relative;
		z-index: 9;
		width: auto;
		top: 12px;
		right: 15px;
		float: right;
		@include transition-default;
		color: #fff;
		ul,
		ul li {
			position: relative;
			display: inline-block;
			margin: 0;
			padding: 0;
			a {
				position: relative;
				margin: 0;
				padding: 0;
				display: block;
				padding: 5px 10px;
				white-space: nowrap;
			}
		}
	}
	/*First level styles */
	.main-menu>ul>li span {
		>a {
			color: #fff;
			padding: 0 8px 15px 8px;
			@include fontSize(14px);
			font-weight: 500;
		}
	}
	header.sticky .main-menu>ul>li span {
		>a {
			color: #444;
		}
		&:hover>a {
			opacity: 1;
			color: $color-1;
		}
	}
	/* Submenu*/
	.main-menu ul ul {
		position: absolute;
		z-index: 1;
		visibility: hidden;
		left: 3px;
		top: 100%;
		margin: 0;
		display: block;
		padding: 0;
		background: #fff;
		min-width: 200px;
		@include box-shadow (0px 6px 12px 0px rgba(0, 0, 0, 0.175));
		transform: scale(0.4);
		transform-origin: 10% top;
		transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
		opacity: 0;
		@include transition-default (all, 0.2s, ease);
		@include border-radius(3px);
		&:before {
			bottom: 100%;
			left: 15%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-bottom-color: #fff;
			border-width: 7px;
			margin-left: -7px;
		}
		li {
			display: block;
			height: auto;
			padding: 0;
			a {
				@include fontSize(12px);
				font-weight: 500;
				color: $body-text-color;
				border-bottom: 1px solid #ededed;
				display: block;
				padding: 15px 10px;
				line-height: $line-height-none;
			}
		}
	}
	.main-menu ul li:hover>ul {
		padding: 0;
		visibility: visible;
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition-delay: 0.2s;
		transition-delay: 0.2s;
		-webkit-transition-duration: 0s, 0.2s, 0.2s;
		transition-duration: 0s, 0.2s, 0.2s
	}
	.main-menu ul ul li {
		&:first-child a {
			&:hover {
				@include border-radius(3px 3px 0 0);
			}
		}
		&:last-child a {
			border-bottom: none;
			&:hover {
				@include border-radius(0 0 3px 3px);
			}
		}
		&:hover>a {
			background-color: #f9f9f9;
			color: $color-1;
			padding-left: 18px;
		}
	}
	
}

/* Submenu 2nd level right */
.main-menu ul ul {
	&.second_level_right {
		left: auto;
		right: 3px;
		&:before {
			right: 15%;
			left: auto;
		}
	}
}

/* Submenu 3rd level */
.main-menu ul ul li span a {
	font-weight: 500!important;
	&:hover {
		color: $color-1;
		padding-left: 18px;
		opacity: 1;
	}
	&:after {
		font-family: 'ElegantIcons';
		content: "\35";
		float: right;
		@include fontSize(16px);
		margin-top: -2px;
	}
}
.main-menu ul ul ul {
	position: absolute;
	border-top: 0;
	z-index: 1;
	height: auto;
	left: 100%;
	top: 0;
	margin: 0;
	padding: 0;
	background: #fff;
	min-width: 190px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
	&.third_level_left {
		right: 100%;
		left: auto;
	}
	li a {
		border-bottom: 1px solid #ededed!important;
	}
	li:last-child a{
			border-bottom: none!important;
	}
}

/* Arrows top 3rd level*/
.main-menu ul ul ul:before {
	border-width: 0;
	margin-left: 0;
}

/* All styles for screen size under 991px */
@media only screen and (max-width: 991px) {
	
	nav#menu {
		display: none !important;
	}
	.mm-menu {
		background: #fff;
	}
	.main-menu {
		top: 0;
		right: 0;
		float: none;
	}
	ul.mm-listview {
		line-height: 25px;
		li {
			a {
				color: $body-text-color!important;
				display: block;
			}
		}
	}
	
}

#menu.fake_menu {
	display: none!important;
	visibility: hidden!important;
}

/*-------- Preloader --------*/
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	bottom: 0;
	background-color: #fff;
	z-index: 99999999999!important;
}

[data-loader="circle-side"] {
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	left: 50%;
	margin-left: -25px;
	margin-top: -25px;
	-webkit-animation: circle infinite .95s linear;
	-moz-animation: circle infinite .95s linear;
	-o-animation: circle infinite .95s linear;
	animation: circle infinite .95s linear;
	border: 2px solid $color-1;
	border-top-color: rgba(0, 0, 0, 0.2);
	border-right-color: rgba(0, 0, 0, 0.2);
	border-bottom-color: rgba(0, 0, 0, 0.2);
	@include border-radius(100%);
}

@-webkit-keyframes circle {
	0% {
		@include rotate(0);
	}
	100% {
		@include rotate(360deg);
	}
}

@-moz-keyframes circle {
	0% {
		@include rotate(0);
	}
	100% {
		@include rotate(360deg);
	}
}

@-o-keyframes circle {
	0% {
		@include rotate(0);
	}
	100% {
		@include rotate(360deg);
	}
}

@keyframes circle {
	0% {
		@include rotate(0);
	}
	100% {
		@include rotate(360deg);
	}
}

/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
*/

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
body {
    background: $body-color;
    font-size: $font-size-base;
    line-height: $line-height-base;
	font-family: $font-main;
    color: $body-text-color;
}
h1,
h2,
h3,
h4,
h5,
h6 {
   color: $heading-color;
}
p {
	margin-bottom: 30px;
}
strong {
	font-weight: 600;
}
label {
	font-weight: 500;
	margin-bottom: 3px;
}
hr {
    margin: 30px 0 30px 0;
	background-color:$border-color-2;
	opacity: 1;
	&.large {
		background-color: $border-color-2;
		height: 4px;
		margin-top: 60px;
		@include tablet {margin-top: 45px;}
		@include mobile {margin-bottom: 45px;}
	}
}
ul,ol {
	list-style: none;
	margin: 0 0 25px 0;
	padding: 0;
}
.main_title {
	text-align: center;
	margin-bottom: 45px;
	position: relative;
}
.main_title span {
	content: "";
	display: block;
    width: 60px;
    height: 3px;
	margin: auto;
	margin-bottom: 20px;
    opacity: 1;
    background-color: #999;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
}
.main_title.animated span{
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
.main_title_2 {
	span {
		width:120px; 
		height:2px; 
		background-color:#e1e1e1; 
		display:block; 
		margin:auto;
		em {
			width:60px; 
			height:2px; 
			background-color:$color-1; 
			display:block; 
			margin:auto;
		}
	}
	text-align: center;
	margin-bottom: 45px;
	position: relative;
	@include mobile {margin-bottom: 10px;}
	h2 {
		margin:25px 0 0 0;
		@include mobile {@include fontSize(26px);}
	}
	h3 {
		margin:25px 0 0 0;
		@include mobile {@include fontSize(24px);}
	}
	p {
		@include fontSize(24px);
		font-weight: 300;
		line-height: 1.4;
		@include mobile {@include fontSize(18px);}
	}
}

.main_title_3 {
	span {
		width:120px; 
		height:2px; 
		background-color:#e1e1e1; 
		display:block; 
		em {
			width:60px; 
			height:2px; 
			background-color:$color-1; 
			display:block; 
		}
	}
	margin-bottom: 25px;
	position: relative;
	@include mobile {margin-bottom: 10px;}
	h2,h3 {
		@include fontSize(23px);
		margin:25px 0 0 0;
		@include mobile {@include fontSize(21px);}
	}
	p {
		@include fontSize(18px);
		font-weight: 300;
		line-height: 1.4;
	}
}

/** Layes slider typography ab buttons **/
h3.slide_typo {
	white-space: nowrap;
	color: #fff !important;
	font-weight: normal;
	@include fontSize(64px);
}
.slide_typo_2 {
	color: #fff;
	@include fontSize(28px);
	white-space: nowrap;
	text-transform: uppercase;
}

.ls-l a.btn_1{
	white-space: nowrap;
	@include mobile {font-size: 11px!important; padding:7px 15px!important;}
}

/*General links color*/
a {	
	color: $color-1;
	text-decoration: none;
	@include transition-default;
	outline:none;
	&:hover, &:focus {color: #111;text-decoration: none; outline:none;}
}
/*-------- 1.2 Buttons --------*/
a.btn_1,
.btn_1 {
    border: none;
    color: #fff;
    background: $color-1;
    outline: none;
	cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: $line-height-none;
	@include transition-default;
	@include border-radius(3px); 
	&:hover {background: $color-2; color:#fff;}
	&.full-width{
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: 5px;
		&.purchase{
			background-color: $color-3;
			&:hover {
				background-color: $color-2;
			}
		}
		&.wishlist{
			border-color: #555;
			color: #555;
		}
	}
	&.medium {
		@include fontSize(16px);
		padding: 18px 40px;
	}
	&.rounded {
		@include border-radius(25px!important);
	}
	&.outline {
		border: 2px solid $color-1;
		color: $color-1;
		padding: 11px 40px;
		background: none;
		&:hover {background: $color-2; color:#fff; border-color:$color-2;}
		&:focus {outline: none;}
	}
}

.closebt {
	@include transition-default;
	cursor:pointer;
	position: absolute;
	@include fontSize(24px);
	top:10px;
	right: 15px;
	color: #fff;
	opacity: 0.6;
	width: 30px;
	height: 30px;
	&:hover {@include rotate(90deg);opacity: 1;}
}

.btn_home_align {
	text-align: right;
	margin-bottom: 25px;
	@include mobile {text-align: center;margin-bottom: 25px;}
}

a.btn_map {
	@include border-radius(3px);
    padding: 8px 12px 8px 12px;
	line-height: 1;
    margin-bottom: 5px;
	display: inline-block;
	border: 1px solid rgba(0,0,0,0.08);
	font-weight: 600;
	@include fontSize(12px);
	color: rgba(0,0,0,.5);
	&:hover{
		color: $color-1;
	}
	&:before{
		font-family: 'ElegantIcons';
		content: "\e081";
		display: inline-block;
		margin-right: 5px;
	}
}
a.btn_map_in,
.btn_map_in {
    border: none;
    color: #fff;
    background: $color-1;
    outline: none;
	cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    font-weight: 600;
    text-align: center;
	width: 100%;
	margin-bottom: 20px;
	display: inline-block;
    line-height: $line-height-none;
	@include transition-default;
	@include border-radius(3px); 
	&:hover {background: $color-2; color:#fff;}
}
/*-------- 1.3 Structure --------*/
/* Header */
header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	padding: 20px 30px;
	z-index: 99999999;
	border-bottom: 1px solid rgba(255, 255, 255, 0);
	@include mobile {
		padding: 15px 15px 5px 15px;
	}
	&.header {
		.logo_sticky {
			display: none;
		}
		&.sticky {
			@include transition-default;
			border-bottom: 1px solid $border-color-2;
			background-color: #fff;
			padding: 15px 20px;
			@include tablet {
				padding: 15px 15px 5px 15px;
			}
			.logo_normal {
				display: none;
			}
			.logo_sticky {
				display: inline-block;
			}
		}
	}
}

ul#top_menu {
	float: right;
	margin: 0 0 0 10px;
	padding: 0;
	@include fontSize(13px);
	@include tablet {
			margin: -2px 0 0 10px;
		}
	li {
		float: left;
		margin: 0;
		line-height:1;
		margin-right: 15px;
		&:last-child {
			margin-right: 0;
			position: relative;
			top: 0;
		}
		@include tablet {
			margin: 0 0 0 10px;
		}
		@include mobile {
			&:first-child {
			display: none;
		}
		}
		a {
			color: #fff;
			&:hover {
				color: #fff;
				opacity: 0.7;
			}
			&.login,
			&.wishlist_bt_top,
			&.cart-menu-btn {
				display: block;
				width: 22px;
				height: 23px;
				position: relative;
				top: 8px;
				@include tablet {
					top: 2px;
				}
				@include transition-default (opacity, 0.5s, ease);
				&:before {
					font-family: 'Glyphter';
					@include fontSize(21px);
					color: #fff;
					text-indent: 0;
					position: absolute;
					left: 0;
					top: 0;
					font-weight: normal;
					line-height: $line-height-none;
					.sticky & {
						color: #444;
					}
				}
			}
			&.wishlist_bt_top {
				text-indent: -9999px;
				&:before {
						content:'\0043';
					}
			}
			&.login {
				text-indent: -9999px;
				&:before {
						content:'\0042';
					}
			}
			&.cart-menu-btn {
				top: 6px;
				@include tablet {
					top: 0;
				}
				&:before {
						content:'\0041';
					}
				strong {
					@include fontSize(11px);
					width: 15px;
					height: 15px;
					display: block;
					background-color: #333;
					color: #fff;
					position: relative;
					bottom: -15px;
					right: -10px;
					text-align: center;
					line-height: 17px!important;
					@include border-radius(50%);
					margin: 0;
					padding: 0;
				}
			}
			&.logged {
				display: block;
				width: 25px;
				height: 25px;
				position: relative;
				overflow: hidden;
				top: 6px;
				@include border-radius(50%);
				img {
					width: 26px;
					height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform (translate(-50%, -50%));
				}
				@include tablet {
					top: 0;
				}
				@include transition-default (opacity, 0.5s, ease);
			}
		}
	}
}

/* Dropdown user logged */
.dropdown-user .dropdown-menu {
	border: none;
	@include border-radius(0);
	min-width: 0;
	padding: 0;
	width: 150px;
	top: 160%;
	margin-left: -115px;
	font-weight: normal;
	text-shadow: none;
	text-transform: none !important;
	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.02);
	border-left: 1px solid rgba(0, 0, 0, 0.02);
	@include border-radius(3px);
	@include box-shadow (3px 2px 7px 1px rgba(0, 0, 0, 0.08));
	@include border-radius(3px);
	padding: 0;
	&:before {
		bottom: 100%;
		right: 10%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-bottom-color: #fff;
		border-width: 7px;
		margin-left: -7px;
	}
	ul {
		padding: 0;
		margin: 0!important;
		list-style: none;
		li {
			border-bottom: 1px solid $border-color-2;
			margin: 0;
			width: 100%;
			margin-bottom: 15px;
			display: inline-block;
			@include fontSize(13px);
			@include tablet {margin: 0!important;}
			a {
				color: #444!important;
				padding: 15px 8px 15px 8px;
				display: block;
				&:hover{
					color: $color-1!important;
					opacity: 1!important;
					padding-left: 15px;
				}
			}
			&:last-child{
				border-bottom: none;
				margin-bottom: 0;
			}
			&:first-child {
				@include tablet {display: block!important;}
			}
		}
	}
}

header.sticky .hamburger-inner, header.sticky .hamburger-inner::before, header.sticky .hamburger-inner::after {
    background-color: #444;
}

#logo {
	float: left;
	@include tablet {
		float: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		img {
			width: auto;
			height: 30px;
			margin: 12px 0 0 0;
		}
	}
}
.hero_in {
	width: 100%;
	height: 450px;
	position: relative;
	overflow: hidden;
	text-align: center;
	color: #fff;
	@include mobile {height: 350px;}
	&.general {
		&:before {
			background: url(../img/hero_in_bg_2.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&.tours {
		&:before {
			background: url(../img/hero_in_bg_1.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&.hotels {
		&:before {
			background: url(../img/hero_in_bg_4.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&.restaurants {
		&:before {
			background: url(../img/hero_in_bg_5.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&.tours_detail {
		height: 550px;
		@include extra-small-mobile {height: 500px;}
		&:before {
			background: url(../img/hero_in_tours_detail.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&.hotels_detail {
		height: 550px;
		@include extra-small-mobile {height: 500px;}
		&:before {
			background: url(../img/hero_in_hotels_detail.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&.restaurants_detail {
		height: 550px;
		@include extra-small-mobile {height: 500px;}
		&:before {
			background: url(../img/hero_in_restaurants_detail.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&.contacts {
		&:before {
			background: url(../img/hero_in_bg_3.jpg) center center no-repeat; 
			@include background-size(cover); 
		}
		.wrapper {@include rgba(bg, #000, 0.6);}
	}
	&:before {
		animation: pop-in 5s .3s cubic-bezier(0, 0.5, 0, 1) forwards;
		content: "";
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		h1 {
			color: #fff;
			@include fontSize(42px);
			text-transform: uppercase;
			font-weight: 700;
            margin-top: 30px;
			@include mobile {@include fontSize(32px);}
			span {
				width: 80px;
				height: 4px;
				margin: auto;
				background-color: $color-5;
				display: block;
                margin: auto;
				margin-bottom: 20px;
				border-radius: 10px;
				opacity: 1;
			}
		}
		p {
			font-weight: 300;
			margin-bottom: 0;
			@include fontSize(24px);
			strong {font-weight: 600;}
		}
	}
	a.btn_photos {
		position: absolute;
		left: 20px;
		bottom:20px;
		background-color: #fff;
		@include border-radius(3px);
		@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.20));
		line-height: 1;
		padding: 10px 15px;
		color: #444;
		font-weight: 500;
		&:hover{
			color: $color-1;
		}
	}
}

/* Animations */
@keyframes pop-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
	  @include transform (scale(1.1));
  }
}

/* Delays */
.hero_in h1{
	@include animation-delay (0.8s);
}
.hero_in p{
	@include animation-delay (1.5s);
	@include animation-duration (0.3s);
}

/* Main */
main {
    background-color: $body-color;
	position: relative;
	z-index: 1;
}

/* Footer */
footer {
	background-color: $color-4;
	@include rgba(color, #fff, 0.7);
	h5 {
		color: #fff;
		margin: 25px 0;
		@include fontSize(18px);
		@include extra-small-mobile {margin: 25px 0 10px 0;}
	}
	ul {
		li {
			margin-bottom: 5px;
			a {
				@include transition-default;
				display: inline-block;
				position: relative;
				color:#fff;
				opacity: 0.7;
				&:hover {
					color: $color-1;
					opacity: 1;
				}
				i {
					margin-right: 10px;
					color: #fff;
				}
			}
		}
		&.links li a {
			&:hover {
				@include transform (translate(5px, 0));
				&:after {
					opacity: 1;
					color: $color-1;
				}
			}
			&:after {
				font-family: 'ElegantIcons';
				content: "\24";
				position: absolute;
				margin-left: 5px;
				top: 1px;
				opacity: 0;
				@include transition-default (all,0.5s, ease);
			}
		}
		&.contacts li a i {
			margin-right: 10px;
		}
	}
	hr {opacity: 0.1;}
	#copy {
		text-align: right;
		@include fontSize(13px);
		opacity: 0.7;
		@include mobile {text-align: left; margin: 5px 0 20px 0;}
	}
}

/* Newsletter footer*/
#newsletter {
	.form-group {
		position: relative;
	}
	.loader {
		position: absolute;
		right: -20px;
		top: 11px;
		color: #fff;
		@include fontSize(12px);
	}
	h6 {
		color: #fff;
		margin:15px 0 15px 0;
	}
	.form-group {
		position: relative;
	}
	input[type='email'] {
		border: 0;
		height: 40px;
		@include border-radius(0);
		padding-left: 15px;
		@include fontSize(14px);
		@include rgba(bg, #fff, 0.06);
		color: #fff;
		&:focus{
			border: 0;
			box-shadow: none;
		}
	}
	input[type='submit'] {
		position: absolute;
		right: 0;
		color: #fff;
		@include fontSize(13px);
		font-weight: 600;
		top: 0;
		border: 0;
		padding: 0 12px;
		height: 40px;
		cursor: pointer;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		@include transition-default;
		outline: none;
		&:hover {
			background-color: $color-5;
			color: #222;
		}
	}
}

.follow_us {
	margin-top: 15px;
	@include animation-delay (1.1s);
	h5 {
		color: #fff;
	}
	ul {
		li {
			display: inline-block;
			margin-right: 10px;
			@include fontSize(20px);
			&:first-child {
				color: #fff;
				text-transform: uppercase;
				font-weight: 500;
				letter-spacing: 2px;
				@include fontSize(13px);
			}
			a {
				color: #fff;
				opacity: 0.7;
				&:hover {opacity: 1;}
			}
		}
	}
}

ul#footer-selector{
	margin: 0 0 0 0;
	padding: 0;
	list-style: none;
	li {
		float: left;
		margin-right: 10px;
		&:last-child{
		@include extra-small-mobile {margin-top: 5px;}
	}
	}
}

/* Addtional links Footer */
ul#additional_links {
    margin: 0;
	padding: 8px 0 0 0;
    color: #555;
    @include fontSize(13px);
	float: right;
	@include tablet {float: none; margin-top: 10px;}
	li {
		display: inline-block;
		margin-right: 15px;
		&:first-child {margin-right: 20px;}
		&:last-child:after {content: "";}
		span {
			color: #fff;
			opacity: 0.5;
		}
		a {
			color: #fff;
			opacity: 0.5;
			@include transition-default;
			&:hover {opacity: 1;}
			
		}
		&:after {content: "|";font-weight: 300;position: relative;left: 10px;}
	}
}

.styled-select {
	width: 100%;
	overflow: hidden;
	height: 30px;
	line-height: 30px;
	border: none;
	@include rgba(bg, #fff, 0.05);
	@include border-radius(3px);
	padding-right: 35px;
	position: relative;
	&#lang-selector {
		&:after {
			font-family: 'ElegantIcons';
			content: "\e0e2";
			color: #fff;
			position: absolute;
			right: 10px;
			top:0;
		}
	}
	&#currency-selector {
		&:after {
			font-family: 'ElegantIcons';
			content: "\33";
			color: #fff;
			position: absolute;
			right: 10px;
			top:0;
		}
	}
	select {
		background: transparent;
		width: 110%;
		padding-left: 10px;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		height: 30px;
		color: #ccc;
		@include fontSize(12px);
		margin: 0;
		font-weight: 500;
		-moz-appearance: window;
		-webkit-appearance: none;
		cursor: pointer;
		outline: none;
		&:focus {
			color: #fff;
			outline: none;
			box-shadow: none;
		}
		&::-ms-expand {
			display: none;
		}
	}
}

.styled-select#lang-selector select option {
  color: #555;
}

/* Wow on scroll event */
 .wow {
	 visibility: hidden;
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/

/*-------- 2.1 Home/front page--------*/
/* ------ jQuery FlexSlider v2.7.0 ----------- */
/* Resets */
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
/* Base style */
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;

}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* Default theme */
.flexslider {
  margin: 0;
  background: #fff;
  position: relative;
  zoom: 1;
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  position: absolute;
  bottom: 50px;
  height: 30px;
  line-height: 1;
    right: 0;
    bottom: 0;
  width: 100%;
  z-index: 9999;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block!important;
  width: 45px;
  height: 52px;
  position: absolute;
  top: -65px;
  z-index: 10;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flex-direction-nav a:before {
  font-family: "ElegantIcons";
  font-size: 46px;
  display: inline-block;
  content: '\34';
  color: rgba(255, 255, 255, 1);
}
.flex-direction-nav a.flex-next:before {
  content: '\35';
}
.flex-direction-nav .flex-next {
  text-align: right;
}
#carousel_slider .flex-direction-nav .flex-prev {
  opacity: 0.8;
  left: 15px;
}
#carousel_slider .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
#carousel_slider .flex-direction-nav .flex-next {
  opacity: 0.8;
  right: 15px;
}
#carousel_slider .flex-direction-nav .flex-next:hover {
  opacity: 1;
}
#carousel_slider .flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}


/* Custom theme */
#slider.flexslider li {
		position: relative;
}

#slider.flexslider li .meta {
	position: absolute;
	bottom: 35%;
	left: 60px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	font-weight: 500;
	z-index: 99;
	@include mobile {
		left: 45px;
		bottom: auto;
		top: 50%;
	}
	padding-right: 45px;
}

#slider.flexslider li h3 {
	@include fontSize(36px);
	color: #fff;
	@include mobile {@include fontSize(24px);}
}

#slider.flexslider li .info {
		display: flex;
		flex-direction: row;
}

#slider.flexslider li .info p {
		margin-bottom: 15px;
		@include fontSize(16px);
		@include mobile {@include fontSize(14px);}
}

#slider.flexslider li h3,
#slider.flexslider li .info p {
		-webkit-animation-duration: .6s;
		animation-duration: .6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: fadeOut;
		animation-name: fadeOut;
}

#slider.flexslider li .meta a {
		-webkit-animation-duration: .3s;
		animation-duration: .3s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: zoomOut;
		animation-name: zoomOut;
		padding: 12px 20px;
}

#slider.flexslider li.flex-active-slide .meta h3,
#slider.flexslider li.flex-active-slide .meta .info p{
		-webkit-animation-delay: .4s;
		animation-delay: .4s;
		-webkit-animation-duration: .6s;
		animation-duration: .6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: fadeInUp;
		animation-name: fadeInUp;
}

#slider.flexslider li.flex-active-slide .meta a{
		-webkit-animation-delay: 1s;
		animation-delay: 1s;
		-webkit-animation-duration: .3s;
		animation-duration: .3s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: zoomIn;
		animation-name: zoomIn;
	
}

#slider.flexslider li.flex-active-slide .meta .info p {
		-webkit-animation-delay: .6s;
		animation-delay: .6s;
}

.slider {
	position: relative;
}

#slider.flexslider {
	overflow: hidden;
	.flex-direction-nav {
		opacity: 0;
		display: none;
	}
	ul.slides {
		li {
			height: 650px;
			background-color: #000;
			@include desktop {
				height: 650px!important;
			}
			@include mobile {
				height: 500px!important;
			}
			img {
				opacity: 0.5;
				width: 100%;
				height: auto;
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform (translate(-50%, -50%));
				@include desktop {
					height: 650px!important;
					width: auto;
				}
				@include mobile {
					height: 500px!important;
					width: auto;
				}
			}
		}
	}
}
#icon_drag_mobile{
	position: absolute;
	right: 20px;
	bottom: 20px;
	background: url(../img/drag_icon.svg) 0 0 no-repeat;
	width: 50px;
	height: 30px;
	opacity: 0.6;
	z-index: 99;
	display: none;
	@include mobile {display: block;}
}

#carousel_slider_wp{
	@include background-gradient(transparent, #000, 'vertical');
	position: absolute;
	bottom: 0;
	z-index: 9;
	width: 100%;
	padding: 0 60px 45px 60px;
	@include mobile {display: none;}
}

#carousel_slider {
    
	&.flexslider {
		overflow: hidden;
		background-color: transparent;
        ul.slides {
				padding-top: 20px;
			}
		ul.slides li {
			position: relative;
			@include border-radius(5px);
			background-color: #000;
			@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.5));
			overflow: hidden;
			cursor: pointer;
			&:hover img{
					@include transform (scale(1.2));
				}
			img {
				@include transform (scale(1));
				opacity: 0.4;
				@include transition-default (all, 0.3s, ease-in-out);
			}
			.caption {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 9;
				width: 100%;
				height: 100%;
				padding: 20px;
				color: #fff;
				h3 {
					@include fontSize(21px);
					color: #fff;
					font-weight: 500;
					span {
						margin: 3px 0 0 0;
						display: block;
						text-transform: uppercase;
						@include fontSize(12px);
					}
				}
				small {
					opacity: 0;
					@include transition-default-cubic-bezier;
					position: absolute;
					bottom: 10px;
					left: 20px;
					display: block;
					width: 100%;
					font-weight: 500;
					@include fontSize(14px);
					@include transform (translate(0, 10px));
					strong {
						float: right;
						display: inline-block;
						text-align: right;
						margin:0 40px 0 0;
						padding: 3px 0 0 0;
						h6 {
							color: #fff;
							margin: 0;
							font-weight: 500;
							@include fontSize(14px);
						}
					}
				}
			}
			&:hover .caption small{
				opacity: 1;
				@include transform (translate(0, 0));
			}
		}
	}
}

a#moveDown{
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 9999;
	@include fontSize(32px);
	color: #fff;
	@include animation-duration (6s);
	@include animation-count (infinite);
}

/* Home video background*/
.header-video {
	position: relative;
	overflow: hidden;
	background: #000;
	height: 600px!important;
	@include tablet {height: 620px!important;}
	video {
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0.4;
	}
	iframe {
		height: 100%;
		width: 100%;
	}
}
#hero_video {
	position: relative;
	background-size: cover;
	color: #fff;
	width: 100%;
	@include fontSize(16px);
	height: 100%;
	z-index: 99;
	text-align: center;
	display: table;
	.wrapper {
		display: table-cell;
		vertical-align: middle;
		h3 {
			color: #fff;
			@include fontSize(42px);
			text-transform: uppercase;
			font-weight: bold;
			margin-bottom: 0;
			text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
			@include mobile {
				@include fontSize(26px);
				margin-top: 60px;
			}
			@include extra-small-mobile {
				@include fontSize(22px);
			}
		}
		p {
			font-weight: 300;
			@include fontSize(24px);
			line-height: 1.4;
			text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
			strong {font-weight: 600;}
			@include mobile {
				padding: 0;
				@include fontSize(14px);
			}
		}
	}
}

.teaser-video {
	width: 100%;
	height: auto;
}
.header-video--media {
	width: 100%;
	height: auto;
}
@media (max-width: 1024px) {
	#hero_video {
		background: #ccc url(../img/home_section_1.jpg);
		@include background-size(cover);
		>div {@include rgba(bg, #000, 0.5);}
	}
	.header-video {
		background: none;
	}
}

/* Home single hero */
.hero_single {	
	width: 100%;
	position: relative;
	overflow: hidden;
	text-align: center;
    margin: 0;
	color: #fff;
	.wrapper {@include rgba(bg, #000, 0.8);}
	&.start_bg_zoom:before {
		animation: pop-in 6s .3s cubic-bezier(0, 0.5, 0, 1) forwards;
		content: "";
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		h3 {
			color: #fff;
			@include fontSize(52px);
			margin: 0;
			text-transform: uppercase;
			font-weight: 700;
			@include mobile {
				@include fontSize(21px);
			}
			@include extra-small-mobile {
				@include fontSize(23px);
			}
		}
		p {
			font-weight: 300;
			margin:10px 0 0 0;
			padding: 0 20%;
			@include fontSize(24px);
			line-height: 1.4;
			strong {font-weight: 600;}
			@include mobile {
				padding: 0;
				@include fontSize(18px);
			}
		}
	}
	&.short{height: 600px;}
	&.version_2 {
		height: 620px;
		&:before {background:url(../img/home_section_1.jpg) center center no-repeat; @include background-size(cover);}
		.wrapper {
			@include rgba(bg, #000, 0.45);
			h3 {
			@include fontSize(42px);
			text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
			@include mobile {
				@include fontSize(26px);
				margin-top: 60px;
			}
			@include extra-small-mobile {
				@include fontSize(22px);
			}
		}
		p {
			font-weight: 400;
			margin:5px 0 20px 0;
			padding: 0;
			@include fontSize(21px);
			strong {font-weight: 600;}
			text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
			@include mobile {
				padding: 0;
				@include fontSize(14px);
			}
		}
		}
	}
}

#custom-search-input {
	padding: 0;
	width: 600px;
	margin: 20px auto 0;
	position: relative;
	@include tablet {
		width: auto;
	}
	.search-query {
		width: 100%;
		height: 50px;
		padding-left: 20px;
		border: 0;
		@include border-radius(3px);
		@include fontSize(16px);
		color: #333;
		&:focus {
			outline: none;
		}
		@include extra-small-mobile {
			padding-left: 15px;
		}
	}
	input[type='submit'] {
		position: absolute;
		@include transition-default;
		right: -1px;
		color: #fff;
		font-weight: 600;
		@include fontSize(14px);
		top: 0;
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		outline: none;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		&:hover {
			background-color: $color-5;
			color: #222;
		}
		@include extra-small-mobile {
			text-indent: -999px;
			background: $color-1 url(../img/search.svg) no-repeat center center;
		}
	}
}

.custom-search-input-2 {
	background-color: #fff;
	@include border-radius(5px);
	margin-top: 15px;
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.3));
	@include tablet {
		background: none;
		@include box-shadow (none);
	}
	input {
		border: 0;
		height: 50px;
		padding-left: 15px;
		border-right: 1px solid $border-color-3;
		font-weight: 500;
		@include tablet {
			border: none;
		}
		&:focus {
			box-shadow: none;
			border-right: 1px solid $border-color-3;
			@include tablet {
				border-right: none;
			}
		}
	}
	select {
		display: none;
	}
	.nice-select .current {
		font-weight: 500;
		color: #6f787f;
	}
	.form-group {
		margin: 0;
		@include tablet {
			margin-bottom: 5px;
		}
	}
	i {
		@include border-radius(3px);
		@include fontSize(18px);
		position: absolute;
		background-color: #fff;
		line-height: 50px;
		top: 0;
		right: 1px;
		padding-right: 15px;
		display: block;
		width: 20px;
		box-sizing: content-box;
		height: 50px;
		z-index: 9;
		color: #999;
		@include tablet {
			padding-right: 10px;
		}
	}
	input[type='submit'] {
		@include transition-default;
		color: #fff;
		font-weight: 600;
		@include fontSize(14px);
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		outline: none;
		width: 100%;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		margin-right: -1px;
		@include tablet {
			margin: 20px 0 0 0;
			@include border-radius(3px);
		}
		&:hover {
			background-color: $color-5;
			color: #222;
		}
	}
	&.inner {
		margin-bottom: 30px;
		@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.1));
		@include tablet {
			margin: 0 0 20px 0;
			@include box-shadow (none);
		}
	}
	&.inner-2 {
		margin: 0 0 20px 0;
		@include box-shadow (none);
		background: none;
		.form-group {
			margin-bottom: 10px;
		}
		input {
			border: 1px solid $border-color-2;
		}
		input[type='submit'] {
			@include border-radius(3px);
			margin-top: 10px;
		}
		i {
			padding-right: 10px;
			line-height: 48px;
			height: 48px;
			top:1px;
		}
		.nice-select{
			border: 1px solid $border-color-2;
		}
	}
}

/* Panel Drop Down */
.panel-dropdown {
    position: relative;
    text-align: left;
    padding: 15px 10px 0 15px;

    @include tablet {
        background-color: #fff;
        @include border-radius(3px);
        height: 50px;
    }

    a {
        color: #727b82;
        font-weight: 500;
        transition: all 0.3s;
        display: block;
        position: relative;

        &:after {
            font-family: 'ElegantIcons';
            content: "\33";
            @include fontSize(24px);
            color: #999;
            font-weight: 500;
            @include transition-default;
            position: absolute;
            right: 0;
            top: -8px;
        }
    }

    &.active a:after {
        transform: rotate(180deg);
    }

    .panel-dropdown-content {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        position: absolute;
        top: 58px;
        left: 0px;
        z-index: 99;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        @include box-shadow (0 12px 35px 2px rgba(0, 0, 0, 0.12));
        padding: 15px 15px 0 15px;
        white-space: normal;
        width: 280px;

        &:before,
        &:after {
            position: absolute;
            display: inline-block;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            content: '';
        }

        &:before {
            top: -7px;
            left: 9px;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-bottom: 7px solid #ccc;
        }

        &:after {
            left: 10px;
            top: -6px;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #fff;
            border-left: 6px solid transparent;
        }

        &.right {
            left: auto;
            right: 0;
        }

        &.right:before {
            left: auto;
            right: 9px;
        }

        &.right:after {
            left: auto;
            right: 10px;
        }
    }
}

.panel-dropdown.active .panel-dropdown-content {
	opacity: 1;
	visibility: visible;
}

.qtyButtons {
	display: flex;
	margin: 0 0 13px 0;
	input {
		outline: 0;
		@include fontSize(16px);
		text-align: center;
		width: 50px;
		height: 36px!important;
		color: #333;
		line-height: 36px;
		margin: 0 !important;
		padding: 0 5px!important;
		border: none;
		box-shadow: none;
		pointer-events: none;
		display: inline-block;
		border: none!important;
	}
	label {
		font-weight: 500;
		line-height: 36px;
		padding-right: 15px;
		display: block;
		flex: 1;
		color: #727b82;
	}
}

.qtyInc,
.qtyDec {
    width: 36px;
    height: 36px;
    line-height: 36px;
    @include fontSize(28px);
    background-color: #f2f2f2;
	-webkit-text-stroke: 1px #f2f2f2;
    color: #333;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
	font-family: 'ElegantIcons';
}

.qtyInc:before { content: "\4c"; }
.qtyDec:before { content: "\4b"; }

.qtyTotal {
	background-color: #66676b;
	border-radius: 50%;
	color: #fff;
	display: inline-block;
	font-size: 11px;
	font-weight: 600;
	font-family: "Open Sans", sans-serif;
	line-height: 18px;
	text-align: center;
	position: relative;
	top: 0;
	left: 2px;
	height: 18px;
	width: 18px;
}

.rotate-x {
	animation-duration: .5s;
	animation-name: rotate-x;
}

@keyframes rotate-x {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(360deg);
	}
}

/*Carousel home page*/
#reccomended {
	margin-top: 40px;
	@include mobile {margin-top: 0;}
	.item {
		margin: 0 15px;
	}
}

#reccomended .owl-item {
	opacity: 0.5;
	transform: scale(0.85);
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(0.85, 0.85);
	transition: all 0.3s ease-in-out 0s;
	overflow: hidden;
}

#reccomended .owl-item.active.center {
	opacity: 1;
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(1, 1);
	transform: scale(1);
}

#reccomended .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views {
	opacity: 1;
}

.owl-theme .owl-dots {
	margin-top: 10px!important;
	margin-bottom: 25px;
	@include mobile {margin-top: 0!important; margin-bottom: 20px;}
}

.owl-theme .owl-dots .owl-dot {
    outline: none;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: $color-1 !important;
}

.owl-theme .owl-dots .owl-dot span {
    width: 13px!important;
    height: 13px!important;
}

a.box_news {
	position: relative;
	display: block;
	padding-left: 230px;
	color: $body-text-color;
	margin-bottom: 30px;
	min-height: 150px;
	@include mobile {
		min-height: inherit;
		padding-left: 0;
	}
	figure {
		width: 200px;
		height: 150px;
		overflow: hidden;
		position: absolute;
		left: 0;
		top: 0;
		@include mobile {
			position: relative;
			width: auto;
		}
		img {
			width: 250px;
			height: auto;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform (translate(-50%, -50%) scale(1.1));
			@include transition-default;
			@include backface-visibility (hidden);
			@include mobile {
				width: 100%;
				max-width: 100%;
				height: auto;
			}
		}
		figcaption {
			background-color: $color-5;
			text-align: center;
			position: absolute;
			right: 0;
			top: 0;
			display: inline-block;
			color: #fff;
			width: 60px;
			height: 60px;
			padding: 15px;
			line-height: $line-height-none;
			strong {
				display: block;
				@include fontSize(21px);
			}
		}
	}
	h4 {
		@include fontSize(18px);
		margin-bottom: 10px;
		@include mobile {margin-bottom: 5px;}
	}
	&:hover {
		img {
			@include transform (translate(-50%, -50%) scale(1));
		}
		h4 {
			color: $color-1;
			@include transition-default;
		}
	}
	ul {
		margin: 0;
		padding: 10px 0 0 0;
		@include mobile {padding: 0;}
		li {
			display: inline-block;
			font-weight: 600;
			text-transform: uppercase;
			color: #999;
			@include fontSize(12px);
			margin-right: 5px;
			&:last-child {
				margin-right: 0;
				&:after {
					content: '';
					margin: 0;
				}
			}
			&:after {
				content: '-';
				margin: 0 0 0 10px;
			}
		}
	}
}

a.grid_item {
	display: block;
	margin-bottom: 25px;
	.info {
		position: absolute;
		width: 100%;
		z-index: 9;
		display: block;
		padding: 25px 20px 5px 20px;
		color: #fff;
		left: 0;
		bottom: 0;
		@include background-gradient(transparent, #000, 'vertical');
		box-sizing: border-box;
		.cat_star {
			@include fontSize(9px);
			i {
				color: #fff;
			}
		}
		h3 {
			color: #fff;
			@include fontSize(18px);
		}
		p {
			color: #fff;
			margin-bottom: 0;
			@include fontSize(15px);
		}
	}
	figure {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		@include mobile {height: 350px!important;}
		@include extra-small-mobile {height: 220px!important;}
		.score {
			position: absolute;
			top:15px;
			right: 15px;
			z-index: 9;
		}
		small {
			position: absolute;
			@include rgba(bg, #000, 0.6);
			left: 20px;
			top:22px;
			text-transform: uppercase;
			color: #ccc;
			font-weight: 600;
			@include border-radius(3px);
			padding: 7px 10px 4px 10px;
			line-height: 1;
			z-index: 9;
		}
		img {
			width: 100%;
			height: auto;
			@include transition-default;
			@include transform (scale(1.1));
		}
	}
	&:hover figure img {
		@include transform (scale(1));
	}
}

.search_container {
	padding: 30px 0 15px 0;
	border-bottom: 1px solid $border-color-2;
}

/* ------ Search 3 index-10.html ----------- */
.custom-search-input-2 {
    &.version_3 {
    	margin-top: 20px;
        .form-group {
            text-align: left;

            span {
                color: #111;
                position: absolute;
                left: 15px;
                top: 12px;
                @include fontSize(12px);
                font-weight: 500;
            }

            input {
                height: 58px;
                padding: 22px 10px 5px 15px;
                @include border-radius(0);
                background-color: transparent;
                @include tablet {background-color: #fff; @include border-radius(3px);}
                &:hover {
                    @include rgba(bg, #000, 0.1);
                    @include border-radius(0);
                    @include tablet {@include rgba(bg, #fff, 1); @include border-radius(3px);}
                }

                &:focus {
                    @include rgba(bg, #000, 0);
                    @include tablet {@include rgba(bg, #fff, 1);}
                }
            }

        }

        .panel-dropdown {
        	@include tablet {height: 58px;}
            a {
                height: 43px;
                padding: 13px 10px 0px 0;
                display: block;

                &:after {
                    display: none !important;
                }

                span {
                    position: relative;
                    top: 0;
                    left: 0;
                    color: #fff;
                }
            }

            &:hover {
                @include rgba(bg, #000, 0.1);
                @include border-radius(0);
                @include tablet {@include rgba(bg, #fff, 1); @include border-radius(3px);}
            }

            &.active {
                @include rgba(bg, #000, 0);
                @include tablet {@include rgba(bg, #fff, 1);}
            }

            .panel-dropdown-content {
                top: 70px;
            }

        }
        input[type='submit'] {
        height: 58px;
     }
    }
}
/*-------- 2.2 About --------*/
a.box_feat {
	text-align: center;
	background: #fff;
	padding: 40px 30px 30px 30px;
	@include mobile {padding: 20px 20px 0 20px;}
	display: block;
	@include transition-default (all, 500ms, ease-in-out);
	@include box-shadow (0px 3px 0px 0px #f0f2f4);
	color: $body-text-color;
	margin-bottom: 30px;
	@include border-radius(3px);
	overflow: hidden;
	&:hover {
		@include transform (translateY(-10px));
		@include box-shadow (0px 3px 0px 0px #ebedef);
	}
	i {
		@include fontSize(70px);
		color: $color-3;
		margin-bottom: 10px;
	}
	h3 {
		@include fontSize(18px);
	}
}

#carousel {
	margin-top: 40px;
	@include mobile {
		margin-bottom: 30px;
	}
	.item {
		.title {
			position: absolute;
			bottom: 0;
			left: 0;
			text-align: center;
			width: 100%;
			z-index: 9;
			h4 {
				@include rgba(bg,$color-2,1);
				display: inline-block;
				@include border-radius(3px 3px 0 0);
				color: #fff;
				padding: 10px 15px;
				margin: 0;
				@include fontSize(16px);
				opacity: 0;
				em {
					display: block;
					@include fontSize(13px);
					font-weight: 300;
					margin-top: 3px;
				}
			}
		}
		a {
			position: relative;
			display: block;
		}
	}
}

#carousel .owl-item {
	opacity: 0.5;
	transform: scale(0.85);
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(0.85, 0.85);
	transition: all 0.3s ease-in-out 0s;
	overflow: hidden;
}

#carousel .owl-item.active.center {
	opacity: 1;
	@include backface-visibility (hidden);
	-webkit-transform: translateZ(0) scale(1, 1);
	transform: scale(1);
}

#carousel .owl-item.active.center .item .title h4,
#carousel .owl-item.active.center .item .views {
	opacity: 1;
}

/*-------- 2.3 Listing --------*/
.filters_listing {
	padding: 10px 0 5px 0;
	background: #fff;
	border-bottom: 1px solid $border-color-2;
	z-index:999999!important;
	position: relative;
	&.is_stuck {
	}
	ul {
		margin: 0 15px;
		@include tablet {margin: 0;}
		li {
			margin-right: 10px;
			&:first-child {
				float: left;
			}
			&:nth-child(2) {
				float: right;
				margin-right: 0;
			}
			&:nth-child(3) {
				float: right;
			}
		}
	}
}
.score {
	strong {
		background-color: $color-2;
		color: #fff;
		line-height: 1;
		@include border-radius(5px 5px 5px 0);
		padding: 10px;
		display: inline-block;
	}
	span {
		display: inline-block;
		position: relative;
		top:7px;
		margin-right: 8px;
		@include fontSize(12px);
		text-align: right;
		line-height: 1.1;
		font-weight: 500;
		em {
			display: block;
			font-weight: normal;
			@include fontSize(11px);
		}
	}
}
.wrapper-grid {
	padding: 0 20px;
}
.box_grid {
	background-color: #fff;
	display: block;
	position: relative;
	margin-bottom: 30px;
	@include box-shadow (0px 0px 20px 0px rgba(0, 0, 0, 0.10));
	.price {
			display: inline-block;
			font-weight: 500;
			color:#999;
			strong {
				color:$color-3;
			}
		}
	a.wish_bt {
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 1;
		@include rgba(bg, #000, 0.6);
		padding: 9px 10px;
		display: inline-block;
		color: #fff;
		line-height: $line-height-none;
		@include border-radius(3px);
		&:after {
			font-family: 'ElegantIcons';
			content: "\e030";
			@include transition-default (all,0.5s, ease);
		}
		&.liked:after{
			content: "\e089";
			color: $color-1;
		}
		&:hover.liked:after{
			color: $color-1;
		}
		&:hover:after {
			content: "\e089";
			color: #fff;
		}
	}
	figure {
		margin-bottom: 0;
		overflow: hidden;
		position: relative;
		height: 210px;
		small {
			position: absolute;
			@include rgba(bg, #000, 0.6);
			left: 20px;
			top:22px;
			text-transform: uppercase;
			color: #ccc;
			font-weight: 600;
			@include border-radius(3px);
			padding: 7px 10px 4px 10px;
			line-height: 1;
		}
		.read_more {
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -12px;
			@include transform (translateY(10px));
			text-align: center;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			-webkit-transition: all 0.6s;
			transition: all 0.6s;
			z-index:2;
			span {
				@include rgba(bg, #fff, 0.8);
				@include border-radius(20px);
				display: inline-block;
				color: #222;
				@include fontSize(12px);
				padding: 5px 10px;
			}
		}
		&:hover .read_more {
			opacity: 1;
			visibility: visible;
			@include transform (translateY(0));
		}
		a {
			img {
				position: absolute;
				left: 50%;
				top: 50%;
				@include transform (translate(-50%, -50%) scale(1.1));
				@include backface-visibility (hidden);
				width: 100%;
				@include transition-default;
			}
			&:hover img {
				@include transform (translate(-50%, -50%) scale(1));
			}
		}
	}
	.wrapper {
		padding: 25px;
		h3 {
			@include fontSize(20px);
			margin-top: 0;
		}
	}
	ul {
		padding: 20px 15px;
		border-top: 1px solid $border-color-2;
		li {
			display: inline-block;
			margin-right: 15px;
			.score {
				margin-top: -10px;
			}
			&:last-child {
				margin-right: 0;
				float: right;
			}
		}
	}
}

.cat_star {
	i {
		margin-right: 2px;
		color: $color-5;
	}
}

.rating {
	color: #ccc;
	.voted {
		color: $color-5;
	}
	i {
		margin-right: 2px;
	}
}

.box_list {
	background-color: #fff;
	display: block;
	position: relative;
	margin: 0 15px 30px 15px;
	min-height: 310px;
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	a.wish_bt {
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 1;
		@include rgba(bg, #000, 0.1);
		padding: 9px 10px;
		display: inline-block;
		color: #555;
		line-height: $line-height-none;
		@include border-radius(3px);
		&:after {
			font-family: 'ElegantIcons';
			content: "\e030";
			@include transition-default (all,0.5s, ease);
		}
		&.liked:after{
			content: "\e089";
			color: $color-1;
		}
		&:hover.liked:after{
			color: $color-1;
		}
		&:hover:after {
			content: "\e089";
			color: #999;
		}
	}
	figure {
		margin-bottom: 0;
		overflow: hidden;
		position: relative;
		min-height: 310px;
		@include tablet {min-height: 220px; max-height: 220px; height: 220px;}
		small {
			position: absolute;
			@include rgba(bg, #000, 0.6);
			right: 20px;
			top:20px;
			text-transform: uppercase;
			color: #ccc;
			font-weight: 600;
			@include border-radius(3px);
			padding: 7px 10px 4px 10px;
			line-height: 1;
			z-index: 99;
			@include tablet {right: 15px; top: 15px;}
		}
		@include tablet {min-height: 220px; max-height: 220px; height: 220px;}
		.read_more {
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -12px;
			@include transform (translateY(10px));
			text-align: center;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			-webkit-transition: all 0.6s;
			transition: all 0.6s;
			z-index:2;
			span {
				@include rgba(bg, #fff, 0.8);
				@include border-radius(20px);
				display: inline-block;
				color: #222;
				@include fontSize(12px);
				padding: 5px 10px;
			}
		}
		&:hover .read_more {
			opacity: 1;
			visibility: visible;
			@include transform (translateY(0));
		}
		a {
				img {
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform (translate(-50%, -50%) scale(1.1));
					@include backface-visibility (hidden);
					width: 100%;
					@include transition-default;
					height: 360px;
					width: auto;
					max-width: none;
					@include transition-default;
					@include tablet {max-width: inherit; height: 460px; width: auto;}
					@include mobile {height: 350px;}
				}
				&:hover img {
					@include transform (translate(-50%, -50%) scale(1));
				}
			}
	}
	.wrapper {
		padding: 30px 30px 20px 30px;
		min-height: 245px;
		position: relative;
		@include tablet {min-height:inherit;}
		h3 {
			@include fontSize(20px);
			margin-top: 0;
		}
		.price {
			display: inline-block;
			font-weight: 500;
			color:#999;
			strong {
				color:$color-3;
			}
		}
	}
	small {
		text-transform: uppercase;
		color: #999;
		font-weight: 600;
	}
	ul {
		padding: 20px 15px 20px 30px;
		border-top: 1px solid $border-color-2;
		margin-bottom: 0;
		li {
			display: inline-block;
			margin-right: 15px;
			.score {
				margin-top: -10px;
			}
			&:last-child {
				margin-right: 0;
				float: right;
			}
		}
	}
}

.layout_view {
	@include border-radius(3px);
	border: 1px solid rgba(0,0,0,0.08);
	padding: 4px 8px 3px 8px;
	a {
		color: rgba(0,0,0,.5);
		&.active {
			color: rgba(0,0,0,0.8);
		}
		&:hover {
			color: $color-1;
		}
	}
}

.switch-field {
	overflow: hidden;
	border: 1px solid rgba(0,0,0,0.08);
	@include border-radius(3px);
	input {
		position: absolute!important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;
		&:checked+label {
			@include box-shadow (none);
			color: rgba(0,0,0,0.9);
		}
	}
	label {
		float: left;
		display: inline-block;
		min-width: 70px;
		color: rgba(0,0,0,.5);
		font-weight: 600;
		@include fontSize(12px);
		text-align: center;
		text-shadow: none;
		padding: 10px 12px 8px 12px;
		line-height: 1;
		border-right: 1px solid rgba(0,0,0,0.08);
		@include transition-default (all,0.2s,ease-in-out);
		@include extra-small-mobile {min-width: inherit;}
		margin: 0;
		&:first-of-type {
			border-left: 0;
		}
		&:last-of-type {
			border-right: 0;
		}
		&:hover {
			cursor: pointer;
			color: $color-1;
		}
	}
}

#filters_col{
	background-color:#fff;
	padding:20px 20px 15px 20px;
	margin-bottom:25px;
	border: 1px solid $border-color-2;
    label{
        color:$heading-color;
        font-weight:normal;
        @include fontSize(13px);
    }
}

a#filters_col_bt {
	display:block;
	color:$heading-color;
	position:relative;
    @include fontSize(16px);
	font-weight: 600;
    &:before{
        font-family: "ElegantIcons";
        content: "\66";
		@include fontSize(26px);
        position:absolute;
        right:0;
        top:0;
        line-height:$line-height-none;
		font-weight: normal;
    }
}

.filter_type {
    h6 {
        border-top: 1px solid $border-color-2;
        margin: 15px 0;
        padding: 15px 0 0 0;
        @include fontSize(13px);
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 15px 0;
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

a.boxed_list {
	text-align: center;
	padding: 30px;
	border: 1px solid $border-color-2;
	display: block;
	@include transition-default;
	margin-bottom: 25px;
	i {
		@include fontSize(60px);
		margin-bottom: 15px;
	}
	h4 {
		@include fontSize(18px);
		color: $heading-color;
	}
	p {
		color: #777;
		margin-bottom: 0;
	}
	&:hover i{
			color:$color-3;
		}
}

/*-------- 2.4 Detail page --------*/
.secondary_nav {
	background: #fff;
	border-bottom: 1px solid $border-color-2;
	padding: 15px 0;
	&.is_stuck {
		z-index: 99;
		width: 100%!important;
		left: 0;
	}
	ul {
		margin-bottom: 0;
		li {
			display: inline-block;
			margin-right: 20px;
			font-weight: 500;
			@include fontSize(16px);
			a {
				color: rgba(0, 0, 0, .5);
				&:hover {
					color: $color-1;
				}
				&.active{
					color: rgba(0, 0, 0, .9);
				}
			}
			&:last-child {
				display: none;
				a {
					background-color: $color-1;
					color: #fff;
					@include border-radius(3px);
					padding: 5px 15px;
					&:hover {
						background-color: $color-2;
					}
				}
				@include extra-small-mobile {display: inline-block; float: right; margin-right: 0;}
			}
		}
	}
}
.intro_title {
	h2 {
        display: inline-block;
    }
     ul {
		   float: right;
            margin: 10px 0 0 0;
            li {
                display: inline-block;
                margin-right: 20px;
				font-weight: 500;
            }
        }
}

section#description, section#reviews {
	border-bottom: 3px solid $border-color-2;
	margin-bottom: 45px;
	h2 {
		@include fontSize(24px);
	}
	h3 {
		@include fontSize(21px);
	}
	h4 {
		@include fontSize(18px);
	}
}
section#reviews {
	border-bottom: none;
}
#review_summary {
	text-align: center;
	background-color: $color-2;
	color: #fff;
	padding: 20px 10px;
	@include border-radius(5px 5px 5px 0);
	@include tablet {
		margin-bottom: 15px;
	}
	strong {
		@include fontSize(42px);
		display: block;
		line-height: $line-height-none;
	}
	em {
		font-style: normal;
		font-weight: 500;
		display: block;
	}
}

.pictures_grid {
  margin-bottom: 45px;
    figure {
        margin: 0;
        overflow: hidden;
        position: relative;
        height: 120px;
        width: 120px;
        display: inline-block;
        @include mobile {width: 80px; height: 80px;}
        a {
            display:block;
             span {
               display: block;
               width: 100%;
               height: 100%;
               position: absolute;
               top:0;
               left: 0;
               z-index: 9;
               @include rgba(bg, #000, 0.7);
               color:#fff;
               @include fontSize(26px);
            }
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                @include transform (translate(-50%, -50%) scale(1));
                @include backface-visibility (hidden);
                width: auto;
                height: 120px;
                z-index: 1;
                @include transition-default;
                @include mobile {height: 80px;}
            }

            &:hover img {
                @include transform (translate(-50%, -50%) scale(1.05));

            }

        }

    }
}

.reviews-container {
	.progress {
		margin-bottom: 12px;
	}
	.progress-bar {
		background-color: $color-2;
	}
	.review-box {
		position: relative;
		margin-bottom: 25px;
		padding-left: 100px;
		min-height: 100px;
		@include mobile {
			padding-left: 0;
		}
	}
	.rev-thumb {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 80px;
		height: 80px;
		background: #ffffff;
		@include border-radius(5px);
		overflow: hidden;
		img {
			width: 80px;
			height: auto;
		}
		@include mobile {
			position: static;
			margin-bottom: 10px;
		}
	}
	.rev-content {
		position: relative;
		padding: 25px 25px 0 25px;
		border: 1px solid $border-color-2;
		@include border-radius(5px);
	}
	.rev-info {
		@include fontSize(12px);
		font-style: italic;
		color: #777;
		margin-bottom: 10px;
	}
}
.box_detail {
	padding: 25px 25px 15px 25px;
	border: 1px solid $border-color-2;
	background-color: #f8f8f8;
	@include border-radius(5px);
	margin-bottom: 30px;
	.price {
		line-height: $line-height-none;
		border-bottom: 1px solid $border-color-2;
		margin: 0 -25px 25px -25px;
		padding: 0 25px 15px 25px;
		>span {
			@include fontSize(31px);
			font-weight: 600;
			>small {
				@include fontSize(11px);
				font-weight: 500;
			}
		}
		.score {
			float: right;
			margin-top: -5px;
		}
	}
	h3 {
		@include fontSize(20px);
		margin: 25px 0 10px 0;
	}
	ul {
		margin-bottom: 0;
		li {
			margin-bottom: 5px;
			i {
				margin-right: 8px;
			}
		}
	}
	figure {
		position: relative;
		background-color: #000;
		img {
			opacity: 0.8;
		}
		span {
			position: absolute;
			display: block;
			left: 0;
			bottom: 10px;
			text-align: center;
			width: 100%;
			color: #fff;
			font-weight: 600;
		}
		a i {
			@include rgba(bg, #000, 0.6);
			display: block;
			width: 60px;
			height: 60px;
			color: #fff;
			@include border-radius(50%);
			line-height: 60px;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -30px 0 0 -30px;
			@include fontSize(42px);
			@include transition-default;
			z-index: 9;
		}
		&:hover i {
			@include transform (scale(1.3));
		}
	}
}

.booking {
	input {
		font-weight: 500;
		height: 45px;
		&:focus {
			box-shadow: none;
			border: 1px solid $border-color-3;
		}
	}
	select {
		display: none;
	}
	.panel-dropdown{
		background-color: #fff;
		@include fontSize(14px);
		@include border-radius(3px);
		border: 1px solid $border-color-3;
		padding-top: 10px;
		padding-bottom: 10px;
		margin: 0 0 10px 0;
		@include tablet {height: inherit;}
		.panel-dropdown-content {
			top: 50px;
		}
	}
	.nice-select .current {
		font-weight: 500;
		color: #6f787f;
	}
	.custom-select-form {
	.nice-select {
		&:active,
		&:focus {
			border-color: $border-color-3;
			outline: 0;
			box-shadow: none;
		}
	}
	select {
		display: none;
	}
}
	.form-group {
		margin: 0 0 10px 0;
		i {
			@include border-radius(3px);
			@include fontSize(18px);
			position: absolute;
			background-color: #fff;
			height: 43px;
			top: 1px;
			right: 1px;
			padding-right: 10px;
			display: block;
			width: 20px;
			box-sizing: content-box;
			line-height: 43px;
			z-index: 9;
			color: #999;
		}
		i.icon_lock_alt {
		  top: 36px;
		}
	}
}

/* Detail page contact form */
.booking textarea {
    font-weight: 500;
    height: 100px;
    padding-right: 25px;
    &:focus {
        border: 1px solid $border-color-3;
        box-shadow: none;
    }
}

.booking i.loader {
  background-color: transparent;
  color: #fff;
  @include fontSize(14px);
  height: auto;
  width: auto;
  padding: 0;
  right: 10px;
}


/* ------ Full view calendar on mobile ----------- */
.datepicker_mobile_full .daterangepicker {
	@include mobile {
		margin-top: 0!important;
		border: none!important;
		position: fixed!important;
		top:0!important;
		left: 0!important;
		right: 0!important;
		width: 100%!important;
		z-index: 9999999!important;
		height: 100%!important;
		overflow-y: scroll!important;
		.calendar {
		    max-width: 100%!important;
		}
	}

}

/*Timeline --------------- */
ul.cbp_tmtimeline {
	margin: 45px 0 45px 0;
	padding: 0;
	list-style: none;
	position: relative;
} 

/* The line */
.cbp_tmtimeline:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 2px;
	background: #ebeef3;
	left: 18.5%;
	margin-left: -2px;
}

/* The date/time */
.cbp_tmtimeline > li {
	position: relative;
}

.cbp_tmtimeline > li .cbp_tmtime {
	display: block;
	width: 23%;
	padding-right: 80px;
	position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
	display: block;
	text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
	font-size: 0.9em;
	color: #888;
	margin-bottom:0;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
	font-size: 18px;
	font-weight:500;
}

/* Right content */
img.thumb_visit {
	width:70px;
	height:70px;
	border:2px solid #fff;
	margin-top:-5px;
	float:right;
	margin-left:15px;
	@include mobile {display: none;}
}
.cbp_tmtimeline > li .cbp_tmlabel {
	margin: 0 0 15px 25%;
	background: #eef3f7;
	padding: 20px;
	position: relative;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
.cbp_tmtimeline > li .cbp_tmlabel p{
	margin-bottom:0;
	@include fontSize(13px);
}

.cbp_tmtimeline > li .cbp_tmlabel h4 { 
	margin:0 0 5px 0;
	padding:0;
	@include fontSize(16px);
}

/* The triangle */
.cbp_tmtimeline > li .cbp_tmlabel:after {
	right: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-right-color: #eef3f7;
	border-width: 10px;
	top: 15px;
}

/* The icons */
.cbp_tmtimeline > li .cbp_tmicon {
	width: 48px;
	height: 48px;
	font-weight:500;
	background-color:#fff;
	text-transform: none;
	@include fontSize(24px);
	line-height: 50px;
	position: absolute;
	color: #ff9933;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #999;
	text-align: center;
	left: 18.5%;
	top: 0;
	margin: 0 0 0 -25px;
}

/* Media Queries */
@media screen and (max-width: 65.375em) {

	.cbp_tmtimeline > li .cbp_tmtime span:last-child {
		font-size: 1.5em;
	}
}

@media screen and (max-width: 47.2em) {
	.cbp_tmtimeline:before {
		display: none;
	}

	.cbp_tmtimeline > li .cbp_tmtime {
		width: 100%;
		position: relative;
		padding: 0 0 20px 0;
	}

	.cbp_tmtimeline > li .cbp_tmtime span {
		text-align: left;
	}

	.cbp_tmtimeline > li .cbp_tmlabel {
		margin: 0 0 30px 0;
		padding: 1em;
	}

	.cbp_tmtimeline > li .cbp_tmlabel:after {
		right: auto;
		left: 20px;
		border-right-color: transparent;
		border-bottom-color: #eef3f7;
		top: -20px;
	}

	.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
		border-right-color: transparent;
		border-bottom-color: #eef3f7;
	}

	.cbp_tmtimeline > li .cbp_tmicon {
		position: relative;
		float: right;
		left: auto;
		margin: -65px 5px 0 0px;
	}	
}

ul.hotel_facilities {
	list-style: none;
	margin:0;
	padding: 0;
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
	li {
		margin-bottom: 10px;
		display: inline-block;
		width: 100%;
		img {
			margin-right: 10px;

		} 
	} 
}

.room_type {
	padding: 30px 30px 10px 30px;
	&.first {
		padding: 0 30px 15px 30px;
	}
	&.gray {
		background-color: #f8f8f8;
	}
	&.last {
		padding: 30px 30px 0 30px;
	}
	h4 {
		@include mobile {margin-top: 25px;}
	}
} 

/*-------- 2.5 Login/register --------*/
#login_bg, #register_bg {
	background: url(../img/hero_in_bg_3.jpg) center center no-repeat fixed;
	@include background-size(cover);
	min-height: 100vh;
	width: 100%;
}
#register_bg {
	background: url(../img/hero_in_bg_2.jpg) center center no-repeat fixed;
	@include background-size(cover);
}

#login, #register {
	figure {
		text-align: center;
		border-bottom: 1px solid $border-color-2;
		margin: -45px -60px 25px -60px;
		padding: 20px 60px 25px 60px;
		@include mobile {margin: -30px -30px 20px -30px; padding: 15px 60px 20px 60px;}
	}
	aside {
		width: 430px;
		padding: 45px 60px 60px 60px;
		position: absolute;
		left: 0;
		top: 0;
		overflow-y: auto;
		background-color: #fff;
		min-height: 100vh;
		@include mobile {width: 100%; padding: 30px;}
		form {
			margin-bottom: 60px;
			display: block;	
			.form-group {
			input {
				  padding-left: 40px;
				}
			  i {
				  @include fontSize(21px);
				  position: absolute;
				  left: 12px;
				  top: 34px;
				  color: #ccc;
				  width: 25px;
				  height: 25px;
				  display: block;
				  font-weight: 400!important;
				}
			}
		}
		.copy {
			text-align: center;
			position: absolute;
			height: 30px;
			left: 0;
			bottom: 30px;
			width: 100%;
			color: #999;
			font-weight: 500;
		}
	}
}

.access_social {
	margin-top: 45px;
	@include mobile {margin-top: 30px;}
}
.divider {
	text-align: center;
	height: 1px;
	margin: 30px 0 15px 0;
	background-color: $border-color-2;
	span {
		position: relative;
		top:-20px;
		background-color: #fff;
		display: inline-block;
		padding: 10px;
		font-style: italic;
}
}
a.social_bt {
	@include border-radius(3px);
	text-align: center;
	color: #fff;
	min-width: 200px;
	margin-bottom: 10px;
	display: block;
	padding: 12px;
	line-height: $line-height-none;
	position: relative;
	@include transition-default;
	cursor: pointer;
	&:hover {
		-webkit-filter: brightness(115%);
		filter: brightness(115%);
	}
	&.facebook,
	&.google,
	&.linkedin {
		&:before {
			font-family: 'ElegantIcons';
			position: absolute;
			left: 12px;
			top: 10px;
			@include fontSize(16px);
			color: #fff;
		}
	}
	&.facebook {
		background-color: #3B5998;
		&:before {
			content: "\e093";
		}
	}
	&.google {
		background-color: #DC4E41;
		&:before {
			content: "\e096";
			top: 12px;
		}
	}
	&.linkedin {
		background-color: #0077B5;
		&:before {
			content: "\e09d";
		}
	}
}

/*Password strength */
#pass-info {
	width: 100%;
	margin-bottom: 15px;
	color: #555;
	text-align: center;
	@include fontSize(12px);
	padding: 5px 3px 3px 3px;
	@include border-radius(3px);
	&.weakpass {
		border: 1px solid #FF9191;
		background: #FFC7C7;
		color: #94546E;
	}
	&.stillweakpass {
		border: 1px solid #FBB;
		background: #FDD;
		color: #945870;
	}
	&.goodpass {
		border: 1px solid #C4EEC8;
		background: #E4FFE4;
		color: #51926E;
	}
	&.strongpass {
		border: 1px solid #6ED66E;
		background: #79F079;
		color: #348F34;
	}
	&.vrystrongpass {
		border: 1px solid #379137;
		background: #48B448;
		color: #CDFFCD;
	}
}

/* Checkbox style */
.container_check {
	display: block;
	position: relative;
	padding-left: 30px;
	line-height: 1.8;
	margin-bottom: 5px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked~.checkmark {
			background-color: $color-2;
			border: 1px solid transparent;
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid $border-color-3;
		background-color: #fff;
		@include border-radius(3px);
		@include transition-default;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 7px;
			top: 3px;
			width: 5px;
			height: 10px;
			border: solid white;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}

.container_check input:checked~.checkmark:after {
	display: block;
}

/*-------- 2.6 Map styles --------*/
.map {
	width: 100%; 
	height: 500px;
	&.map_single {
		height: 400px;
	}
}
.infoBox {
	-webkit-animation: fadeIn 0.9s;
	animation: fadeIn 0.9s;
	padding-right: 50px;
	>img {
		position: absolute!important;
		right: 60px!important;
		top: 10px!important;
		z-index: 9999;
		width: 20px;
		height: 20px;
		display: block;
		cursor: pointer;
	}
}

@-webkit-keyframes fadeIn {
    from {opacity: 0; }
    to {opacity: 1; }
}
.marker_info {
	width: 240px;
	height: 270px;
	border-radius: 5px;
	text-align: left;
	background: #000;
	background: rgba(255, 255, 255, 1);
	position: relative;
	z-index: 999;
	font-family: "Poppins", Helvetica, sans-serif;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
	img {
		display: block;
		@include border-radius(5px 5px 0 0);
	}
	h3 {
		@include fontSize(16px);
		line-height: 1.1;
		font-weight: 500;
		margin-bottom: 3px;
		color: #444;
	}
	em {
		display: inline-block;
		@include fontSize(12px);
		color: #999;
	}
	span {
		display: block;
		padding: 20px 20px 0 20px;
		@include fontSize(13px);
		line-height: 1.2;
		color: #fff;
		position: relative;
		strong {
			display: block;
			font-weight: 500;
			
		}
	}
	&:after{
		right: 100%;
		top: 56%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(0, 0, 0, 0);
		border-right-color: rgba(255, 255, 255, 1);
		border-width: 12px;
		margin-top: -12px;
	}
}
a.btn_infobox_detail{
	position:absolute;
	top:20px;
	right:15px;
	width:20px;
	height:20px;
}
a.btn_infobox_detail:before, .btn_infobox_get_directions:before,a.btn_infobox_phone:before{
	 font-style: normal;
  	font-weight: normal;
  	font-family: "ElegantIcons";
  	@include fontSize(20px);
  	cursor:pointer;
}
a.btn_infobox_detail:before{
  content: "\70";
  color:#ccc;
	&:hover {
	  color: $color-1;
	}
}
.btn_infobox_get_directions, a.btn_infobox_phone{
	border:none;
	display:inline-block;
	font-weight:500;
	color: $color-1;
	background:none;
	cursor:pointer;
	@include fontSize(13px);
	position:relative;
	padding-left:20px;
	margin-bottom:10px;
	outline:none;
	&:focus {
	  outline:none;
	}
}
.btn_infobox_get_directions:hover, a.btn_infobox_phone:hover{color:#333;}
.btn_infobox_get_directions:before{
  @include fontSize(14px);
  content: "\e080";
  position:absolute;
  left:0;
  top:0;
}
a.btn_infobox_phone:before{
  font-size:14px;
  content: "\e090";
  position:absolute;
  left:0;
  top:-2px;
}
span.infobox_rate{
	display:inline-block;
	margin:-44px 0 0 -20px;
	float:left;
	background-color:$color-2;
	padding:5px 8px;
	font-weight:500;
	text-align:left;
	@include fontSize(12px);
}

/* Cluster styles */
.cluster img { display:none }

.cluster-visible {
	text-align: center;
	font-size: 15px !important;
	color: #fff !important;
	font-weight: 500;
	border-radius: 50%;
	width: 36px !important;
	height: 36px !important;
	line-height: 36px !important;
	background-color: $color-1;
}

.cluster-visible:before {
	border: 7px solid $color-1;
	opacity: 0.2;
	box-shadow: inset 0 0 0 4px $color-1;
	content: '';
	position:absolute;
	border-radius: 50%;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {
	0%,100% {
		transform:scale(1.3) rotate(0deg)
	}
	50% {
		transform:scale(1.5) rotate(90deg)
	}
}


/*-------- 2.7 Contacts--------*/
.map_contact{
	width: 100%;
	height: 465px;
	@include tablet {height: 350px; margin-bottom: 45px;}
}
.contact_info{
	background-color: $color-4;
	color: #fff;
	padding: 30px 0;
	text-align: center;
	ul {
		margin: 0;
		padding: 0;
		li {
			float: left;
			width: 33.33%;
			position: relative;
			padding: 0 20px;
			span {
				opacity: 0.7;
			}
			h4 {
				color: #fff;
				@include fontSize(18px);
				color: $color-1;
			}
			i {
				color: #fff;
				@include fontSize(46px);
				margin-bottom: 10px;
			}
			@include mobile {
				width: 100%;
				float: none;
				margin-bottom: 35px;
				padding: 0;
				&:last-child {
					margin-bottom: 5px;
				}
			}
		}
	}
}
.error_message{
	font-weight: 500;
	color: #e3464c;
}
/*-------- 2.8 404 page --------*/
#error_page {
	width: 100%;
	height: 650px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: $color-2 url(../img/pattern_1.svg) center bottom repeat-x;
	@include mobile {background-size: auto 300px;}
	color: #fff;
	h2 {
		margin-top: 30px;
		@include fontSize(140px);
		color: #fff;
		text-transform: uppercase;
		font-weight: bold;
		line-height: $line-height-none;
		i {
			color: $color-5;
		}
		@include mobile {
			@include fontSize(72px);
		}
	}
	p {
		@include fontSize(21px);
		@include mobile {
			@include fontSize(18px);
		}
	}
	@include mobile {
		padding: 0;
		height: 500px;
	}
}

.search_bar_error {
	position: relative;
	margin-bottom: 60px;
	input[type='text'] {
		border: 0;
		height: 50px;
		@include border-radius(3px);
		padding-left: 15px;
		@include box-shadow (0px 0px 50px 0px rgba(0, 0, 0, 0.15));
	}
	input[type='submit'] {
		position: absolute;
		right: -1px;
		color: #fff;
		font-weight: 600;
		top: 0;
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		@include transition-default;
		&:hover {
			background-color: $color-5;
			color: #333;
		}
	}
}

/*-------- 2.9 Cart section --------*/
.cart_section {
	background: $color-2 url(../img/pattern_1.svg) center bottom repeat-x;
	@include mobile {background-size: auto 300px;}
	&.last {
		height: 600px;
		@include mobile {height: 500px;}
	}
}

.bs-wizard {
	width: 100%;
	margin: 50px auto 0;
	>.bs-wizard-step {
		padding: 0;
		position: relative;
		width: 33.33%;
		float: left;
		&.complete>.progress>.progress-bar {
			width: 100%;
		}
		&.active>.progress>.progress-bar {
			width: 50%;
		}
		&:first-child.active>.progress>.progress-bar {
			width: 0%;
		}
		&:last-child.active>.progress>.progress-bar {
			width: 100%;
		}
		&.disabled>.bs-wizard-dot {
			background-color: $color-2;
			&:after {
				opacity: 0;
			}
		}
		&:first-child>.progress {
			left: 50%;
			width: 50%;
		}
		&:last-child>.progress {
			width: 50%;
		}
		.bs-wizard-stepnum {
			@include fontSize(14px);
			font-weight: 600;
			text-transform: uppercase;
		}
		>.bs-wizard-dot {
			position: absolute;
			width: 50px;
			height: 50px;
			display: block;
			top: 36px;
			left: 50%;
			margin-top: -5px;
			margin-left: -25px;
			@include border-radius(50%);
			border: 2px solid #fff;
			background-color: $color-2;
			&:after {
				content: '\e903';
				font-family: 'Fontello';
				@include border-radius(50px);
				position: absolute;
				top: -2px;
				left: 9px;
				@include fontSize(32px);
				color: $color-5;
			}
		}
		>.progress {
			position: relative;
			@include border-radius(0);
			height: 2px;
			@include box-shadow (none);
			margin: 34px 0;
			>.progress-bar {
				width: 0px;
				@include box-shadow (none);
				background: #fff;
			}
		}
	}
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
	pointer-events: none;
}

.table.cart-list {
    margin-bottom: 0;

    td {
        vertical-align: middle;
        padding: 15px;

        strong {
            font-weight: 500;
        }
    }
}

.table.cart-list th,
.table.options_cart th {
    background-color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border: 0;
    padding-top: 0;
}

.table.cart-list tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .025);
}

.table>:not(:first-child) {
    border-top: 1px solid #ddd;
}

@include tablet {
	.table.cart-list,
	.table.cart-list thead,
	.table.cart-list tbody,
	.table.cart-list th,
	.table.cart-list td,
	.table.cart-list tr {
		display: block;
		border: 0;
	}
	.table.cart-list thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	.table.cart-list tr {
		border: 1px solid #eee;
	}
	.table.cart-list td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
			&:before {
			position: absolute;
			top: 12px;
			left: 12px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}
	}
	.table.cart-list td{
		&:nth-of-type(1):before, &:nth-of-type(2):before, &:nth-of-type(3):before, &:nth-of-type(4):before {
		font-weight: 600;
		color: #111;
	}
	}
	.table.cart-list td:nth-of-type(1):before {
		content: "Item";
	}
	.table.cart-list td:nth-of-type(2):before {
		content: "Discount";
	}
	.table.cart-list td:nth-of-type(3):before {
		content: "Total";
	}
	.table.cart-list td:nth-of-type(4):before {
		content: "Actions";
	}
	td.options {
		text-align: left !important;
	}
}

.thumb_cart {
	@include border-radius(50%);
	overflow: hidden;
	width: 60px;
	height: 60px;
	margin-right: 10px;
	float: left;
	img {
		width: 60px;
		height: auto;
	}
	@include tablet {display: none;}
}

.item_cart {
	display: inline-block;
	margin: 20px 0 0 15px;
	font-weight: 500;
	@include tablet {margin: 5px 0 0 0;}
}

#total_cart {
	@include fontSize(24px);
	font-weight: 500;
	border-bottom: 1px solid $border-color-2;
	margin: 0 -25px 20px -25px;
	padding: 0 25px 15px 25px;
	line-height: 1;
	span {
		font-weight: 600;
	}
}

ul.cart_details{
	margin: 0 0 25px 0;
	padding: 0 0 15px 0;
	border-bottom: 1px solid $border-color-2;
	li {
			font-weight: 500;
			span {
				float: right;
			}
		}
}

.form_title {
	position: relative;
	padding-left: 55px;
	margin-bottom: 10px;
}
.form_title {
	position: relative;
	padding-left: 55px;
	margin-bottom: 0;
	h3 {
		margin: 0;
		padding: 0;
		@include fontSize(21px);
		strong {
			background-color: $color-1;
			text-align: center;
			width: 40px;
			height: 40px;
			display: inline-block;
			@include border-radius(50%);
			color: #fff;
			@include fontSize(18px);
			line-height: 42px;
			text-align: center;
			position: absolute;
			left: 0;
			top: -5px;
		}
	}
	p {
		color: #999;
		margin: 0;
		padding: 0;
		@include fontSize(12px);
		line-height: 14px;
	}
}
.cards-payment {
	margin-top: 28px;
	@include tablet {display: none;}
}
.box_cart .step {
	padding: 15px 55px 0 55px;
	margin: 0 0 0 0;
	@include tablet {padding: 15px 0 0 0;}
}
.message {
	background-color: #f8f8f8;
	padding: 15px;
	margin-bottom: 30px;
	@include border-radius(3px);
	p {
		margin: 0;
	}
}

#confirm {
	text-align: center;
	@include fontSize(16px);
	margin-top: 20px;
	h4 {
		color: #fff;
	}
	p {
		@include fontSize(16px!important);
		margin-bottom: 0;
	}
}

.box_cart {
	margin-bottom: 25px;
	hr {
		margin: 30px -25px 30px -25px;
	}
	label {
		font-weight: 500;
	}
	a.btn_1.outline, .btn_1.outline {
		border: 2px solid #999;
		color: #999;
		padding: 12px 40px;
		&:hover {
			color: #fff;
			border: 2px solid $color-2;
		}
	}
}

.cart-options {
	position: relative;
	padding: 25px 0px 0px 0px;
	@include tablet {
		.fix_mobile {
			float: left !important;
		}
	}
}

.apply-coupon {
	.form-group {
		position: relative;
		float: left;
		margin-right: 10px;
	}
	.form-control {
		padding: 10px 12px;
	}
}

/*-------- 2.10 Media Gallery --------*/
.grid ul {
	margin: 0 0 25px 0;
	padding: 0;
	width: 100%;
	text-align: center;
	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		margin: -3px;
		min-height: 100%;
		width: 25%;
		background-color: #000;
		list-style: none;
		@include tablet {width:50%;}
		@include mobile {width:100%;min-height:100%;float:none;}
		figure {
			position: relative;
			overflow: hidden;
			margin: -3px;
			img {
				width: 100%;
				height: 100%;
				@include transition-default;
			}
			&:hover img,
			&:focus img {
				 @include transform (scale(1.1));
			}
			&:hover figcaption,
			&:focus figcaption {
				opacity: 1;
			}
			&:hover figcaption .caption-content,
			&:focus figcaption .caption-content {
				@include transform (translate(0px, 0px));
			}
		}
		figcaption {
			position: absolute;
			top: 0;
			left: 0;
			padding: 15% 0;
			width: 100%;
			height: 100%;
			@include rgba(bg, #000, 0.6);
			text-align: center;
			@include fontSize(14px);
			opacity: 0;
			@include transition-default;
			.caption-content {
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -30px;
				margin-left: -100px;
				width: 200px;
				@include transform (translate(0px, 15px));
				@include transition-default;
			}
			a {
				color: #fff;
				&:hover,
				&:focus {
					color: $color-1;
				}
			}
			i {
				@include fontSize(30px);
			}
			p {
				margin-bottom: 0;
				margin-top: 10px;
				text-transform: uppercase;
				font-weight: 400;
			}
		}
	}
}

.mfp-zoom-in {
	.mfp-with-anim {
		opacity: 0;
		transition: all 0.2s ease-in-out;
		transform: scale(0.8);
	}
	&.mfp-bg {
		opacity: 0;
		transition: all 0.3s ease-out;
	}
	&.mfp-ready {
		.mfp-with-anim {
			opacity: 1;
			transform: scale(1);
		}
		&.mfp-bg {
			opacity: 0.8;
		}
	}
	&.mfp-removing {
		.mfp-with-anim {
			transform: scale(0.8);
			opacity: 0;
		}
		&.mfp-bg {
			opacity: 0;
		}
	}
}

/*-------- 2.11 Faq --------*/
.box_style_cat {
	background: #fff;
	border: 1px solid $border-color-2;
	margin-bottom: 25px;
	@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
	@include tablet {display: none;}
}

ul#cat_nav {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		i {
			@include fontSize(16px);
			margin:2px 10px 0 0;
			float: left;
		}
		border-bottom: 1px solid $border-color-2;
		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
		a {
			position: relative;
			color: $body-text-color;
			display: block;
			padding: 15px 10px;
			&:after {
				font-family: "fontello";
				content: "\e89b";
				position: absolute;
				right: 15px;
				top: 15px;
			}
			span {
				@include fontSize(11px);
				color: #999;
			}
			&:hover,
			&#active,
			&.active {
				color: $color-1;
			}
		}
	}
}

.hero_in form{
	@include animation-delay (1.3s);
}
a.box_topic {
	text-align: center;
	background: #fff;
	padding: 40px 30px 15px 30px;
	@include mobile {padding: 20px 20px 0 20px;}
	display: block;
	@include transition-default;
	@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
	color: $body-text-color;
	margin-bottom: 30px;
	overflow: hidden;
	&:hover {
		@include transform (translateY(-10px));
	}
	i {
		@include fontSize(60px);
		color: $color-3;
		margin-bottom: 10px;
		@include border-radius(50px);
		@include rgba(bg, $color-3, 0.05);
		width: 100px;
		height: 100px;
		line-height: 100px;
		margin-bottom: 15px;
	}
	h3 {
		@include fontSize(18px);
	}
}

.list_articles {
	ul {
		list-style: none;
		margin: 0;
		padding:0;
		li {
			float: left;
			width: 45%;
			margin-right: 45px;
			padding: 15px 0;
			border-bottom: 1px solid $border-color-2;
			a {
				color: $body-text-color;
				display: block;
				&:hover {
					color: $color-1;
					@include transform (translateX(5px));
				}
			}
			i {
				margin-right: 10px;
				@include fontSize(16px);
				position: relative;
				top: 2px;
			}
			@include tablet {
				float: none;
				width: 100%;
				margin-right: 0;
			}
		}
	}
}

ul.list_3 {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		margin-bottom: 0;
		position: relative;
		padding-left: 20px;
		strong {
			font-weight: 500;
		}
		&:before {
			font-family: "fontello";
			content: "\ea3e";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

.box_general {
	border: 1px solid $border-color-2;
	padding: 25px 25px 5px 25px;
	margin-bottom: 25px;
	@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
}

/*-------- 2.12 Adventure --------*/
.header-video.adventure {
	.wrapper {
		padding-top: 60px;
		text-align: left;
		@include fontSize(14px);
		small {
			text-transform: uppercase;
			font-weight: 500;
			@include fontSize(14px);
			display: inline-block;
			margin-bottom: 15px;
		}
		h3 {
		@include mobile {
			font-size: 36px!important;
			margin-top: 0!important;
		}
		}
		p {
			max-width: 400px;
			@include mobile {
				font-size: 21px!important;

			}
		}
	}

}

@media (max-width: 1024px) {
	.header-video.adventure #hero_video {
		background: #ccc url(../img/bg_fallback_video_adventure.jpg) center right;
		@include background-size(cover);
		>div {@include rgba(bg, #000, 0.5);}
	}
	.header-video.adventure {
		background: none;
	}
}

a.grid_item_adventure {
	display: block;
	margin-bottom: 25px;
	.info {
		position: absolute;
		width: 100%;
		z-index: 9;
		display: block;
		padding: 25px 20px 5px 20px;
		color: #fff;
		left: 0;
		bottom: 0;
		@include background-gradient(transparent, #000, 'vertical');
		box-sizing: border-box;
		h3 {
			color: #fff;
			@include fontSize(18px);
		}
		p {
			color: #fff;
			margin-bottom: 0;
			@include fontSize(15px);
		}
	}
	figure {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		.score {
			position: absolute;
			top:15px;
			right: 15px;
			z-index: 9;
		}
		em {
			font-style: normal;
			font-weight: 500;
			@include fontSize(12px);
			text-transform: uppercase;
		}
		img {
			width: 100%;
			height: auto;
			@include transition-default;
			@include transform (scale(1.1));
		}
	}
	&:hover figure img {
		@include transform (scale(1));
	}
}

a.grid_item.latest_adventure{
	h3 {
			@include fontSize(18px);
		}
	em {
			text-transform: uppercase;
			@include fontSize(12px);
			font-weight: 500;
			font-style: normal;
		}
}

.banner{
	background: url(../img/banner_bg_desktop.jpg) center center no-repeat;
   	@include background-size(cover);
   	width: 100%;
   	height: 420px;
   	margin-bottom: 60px;
   	position: relative;
   	@include mobile {
			background: url(../img/banner_bg_mobile.jpg) center center no-repeat;
   			@include background-size(cover);
		}
   	.wrapper {
   		color: #fff;
		padding: 60px;
		@include mobile {
			padding: 30px;
		}
		small {
			text-transform: uppercase;
			@include fontSize(14px);
			font-weight: 500;
		}
		h3 {
			color: #fff;
			@include fontSize(32px);
			margin:15px 0 0 0;
		}
		p {
			@include fontSize(21px);
		}
	}
}

.call_section.adventure {
	background: url(../img/bg_call_action_adventure.jpg) center center no-repeat fixed;
   	@include background-size(cover);
}

.adventure_feat {
		h3 {
			@include fontSize(21px);
			margin-top: 20px;
		}
	}

.hero_in {
    &.adventure_detail {
        text-align: left;
        height: 600px;
        @include mobile {height: 550px;}
        &:before {
            background: url(../img/bg_adventure_detail.jpg) center center no-repeat;
            @include background-size(cover);
        }

        .container {
            position: relative;
            height: 100%;
        }

        .wrapper {
        	a.btn_photos {
        		left: 30px;
			    bottom: 35px;
			}
            .main_info {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 999;
                padding-bottom: 30px;

                em {
                    font-style: normal;
                    text-transform: uppercase;
                }
                h1 {
                    color: #fff;
                    margin: 0;
                    text-transform: none;
                    @include fontSize(36px);
                    font-weight: 600;
                    @include mobile {@include fontSize(32px);}
                }
                h3 {
                    color: #fff;
                    margin: 0;
                    @include fontSize(26px);
                    font-weight: 400;
                    @include mobile {margin-top: 10px;@include fontSize(21px); }
                }
                p {
                    font-weight: 300;
                    margin-bottom: 0;
                    @include fontSize(14px);

                    strong {
                        font-weight: 600;
                    }
                }
                 ul {
                   margin:10px 0 0 0;
                   padding-bottom: 0;
                   list-style: none;

                    li {
                       display: inline-block;
                       line-height: 1.2;
                       margin-right: 30px;
                       &:last-child {
	                       margin-right: 0;
	                    }
                       span {
	                       display: block;
	                       margin-top: 5px;
	                       @include fontSize(12px);
	                    }
                        i {
                        	margin-bottom: 5px;
	                       display: block;
	                       @include fontSize(24px);
	                    }
                    }
                }

            }

        }
    }
}

.adventure_detail {
		.container{
			position: relative;
			height: 100%;
		}
		.main_info{
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 999;
			padding: 0 15px;
			padding-bottom: 25px;
		}
	}

.adventure_description {
		h2{
			@include fontSize(26px);
		}
	}

ul.list_feat {
    margin: 0 0 25xp 0;
    padding: 0;
    list-style: none;
    li {
	    float: left;
	    width: 30%;
	    margin:0 15px 15px 0;
	    border: 1px solid $border-color-2;
	    padding: 25px;
	    min-height: 180px;
	    @include tablet {width: 45%;}
	    @include extra-small-mobile {width: 100%; min-height: inherit;}
	    h4 {
		    @include fontSize(16px);
		    margin:0;
		}
		p {
		    margin:0;
		    color: #777;
		}
	}
}

/* Opacity mask when left open */
.layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.35);
    @include transition-default (all,0.1s, ease);
    opacity: 0;
    visibility: hidden;
}
.layer-is-visible {
    opacity: 1;
    visibility: visible;
}

#panel_dates {
	right: -100%;
	bottom: 0;
	width: 400px;
	height: 100%;
	opacity: 0;
	position: fixed;
	background-color: #fff;
	transition: all 0.3s ease-in-out 0s;
	-moz-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	-webkit-transition: all 0.3s ease-in-out 0s;
	z-index: 9999999;
	-webkit-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	-moz-box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	box-shadow: 1px 0px 5px 0px rgba(50, 50, 50, 0.55);
	overflow: auto;
	overflow-x :hidden;
	@include mobile {width: 100%;}
	&.show {
		visibility: visible;
		opacity: 1;
		right: 0;
	}
	a.aside-panel-bt {
		font-size: 24px;
		position: relative;
		left: 10px;
		top: 0;
		width: 24px;
		height: 24px;
		color: #ccc;
		&:hover {
			color: #333;
		}
	}
}

.additional_txt {
    padding: 0 25px 10px 25px;
    p {
	    @include fontSize(12px);
	    line-height: 1.4;
	    margin-bottom: 10px;
	}
}

.timeline {
   padding-left: 25px;
   border-left: 1px solid $border-color-2;
   h3 {
	   @include fontSize(18px);
	   position: relative;;
	   &:before {
		   font-family: 'ElegantIcons';
			content: "\5c";
			position: absolute;
			left: -35px;
			top: -3px;
			@include fontSize(21px);
			color: #ccc;
			background-color: #fff;
		}
	}
}

.pictures {
  margin-bottom: 45px;
    figure {
        margin: 0;
        overflow: hidden;
        position: relative;
        height: 100px;
        width: 100px;
        display: inline-block;

        a {
            display:block;
             span {
               display: block;
               width: 100%;
               height: 100%;
               position: absolute;
               top:0;
               left: 0;
               z-index: 9;
               @include rgba(bg, #000, 0.7);
               color:#fff;
               @include fontSize(26px);
            }
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                @include transform (translate(-50%, -50%) scale(1));
                @include backface-visibility (hidden);
                width: 100%;
                z-index: 1;
                @include transition-default;
            }

            &:hover img {
                @include transform (translate(-50%, -50%) scale(1.05));

            }

        }

    }
}
/*-------- 2.13 Home AirBnb --------*/
/* Home single hero */
.hero_single {
    &.version_3 {
        height: 690px;

        &:before {
            background: url(../img/home_section_3.jpg) center center no-repeat;
            @include background-size(cover);
        }

        .wrapper {
            @include rgba(bg, #000, 0.45);

            .main_search {
                background-color: #fff;
                @include border-radius(5px);
                width: 460px;
                text-align: left;
                padding:30px 30px 15px 30px;
                @include mobile { width: auto;}
                h3 {
	                color: $heading-color;
	                @include fontSize(28px);
	                text-transform: none;
	                font-weight: 500;
	            }
	            p {
	                color: $body-text-color;
	                @include fontSize(18px);
	                padding:0;
	                margin-bottom: 20px;
	            }
	            .custom-search-input-2 {
					background-color: transparent;
						@include box-shadow (none);
						.form-group{
							margin-bottom: 15px;
						}
						i {
						    
						    line-height: 48px;
						    top: 1px;
						    right: 1px;
						    padding-right: 12px;
						    height: 46px;
						}
						input{
							border: 1px solid $border-color-3;
						}
						.panel-dropdown {
							border: 1px solid $border-color-3;
							@include border-radius(3px);
							height: 50px;
							padding: 14px 12px 0 15px;
						}
						input[type='submit'] {
							margin: 20px 0 0 0;
							border:none;
							width: auto;
							@include border-radius(3px);
						}
				}
            }
        }
    }
}
/*-------- 2.14 Half screen map --------*/
/* Header */
header {
    &.header {
        &.map_view {
            padding: 12px 20px;
            background-color: #fff;
            border-bottom: 1px solid $border-color-2;
            @include tablet {
				padding: 15px 15px 5px 15px;
			}
            ul#top_menu li a {

                &.login,
                &.wishlist_bt_top,
                &.cart-menu-btn {
                    &:before {
                        color: #444;
                    }
                }
            }

            .main-menu>ul>li span {
                >a {
                    color: #444;
                }

                &:hover>a {
                    opacity: 1;
                    color: $color-1;
                }
            }
          .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			    background-color: #444;
			}
        }
    }
}
.full-height {
	height: 100%;
	@include tablet {
		height: auto;
	}
}

.row-height {
	height: 100%;
	min-height: 100%;
	padding-top: 62px;
	@include tablet {
		height: auto;
		padding-top: 50px;
	}
}

.map-right {
	height: 100%;
	min-height: 100%;
	padding: 0px;
	position: fixed;
	top:0;
	right: 0;
	z-index: 0;
	z-index: 9999;
	@include tablet {
		height: 400px;
		position: static;
		min-height: inherit;
	}
	#map {
		height: 100%;
		width: 100%;
	}
}

.content-left {
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
	background-color: $body-color;
	@include tablet {
		padding-top: 0;
	}
}

.search_map_wp {
	padding: 10px 0 10px 0;
	display: none;
	width: 100%;
	.nice-select {
		margin-bottom: 5px;
		border: none;
	}
	.form-control {
		margin-bottom: 10px;
	}
	input[type='submit'] {
		background-color: $color-5;
		color: #222;
		&:hover {
			background-color: $color-3;
			color: #fff;
		}
	}
}
a.btn_search_map_view {
	background: url(../img/search.svg) no-repeat center center;
	background-size: 21px 21px;
	width: 21px;
	height: 21px;
	float: right;
	display: block;
}

#results_map_view {
	padding: 10px 0;
	margin: 0 -15px;
	color: #fff;
	background: #0054a6;
	position: relative;
	z-index: 99999999!important;
	h4 {
		color: #fff;
		margin: 3px 0 0 0;
		padding: 0;
		line-height: 1;
		@include fontSize(16px);
		@include tablet {margin: 5px 0 0 0;}
		@include mobile {margin: 3px 0 0 0;}
	}
}

.custom-search-input-2 {
	&.map_view {
		background-color: transparent;
		@include box-shadow (none);
		input{
			border: none;
		}
		input[type='submit'] {
			margin: 20px 0 0 0;
			@include border-radius(3px);
		}
	}
}

.filters_listing {
	&.version_3 {
		margin: 0 -15px 0 -15px;
		ul {
			margin: 2px 0 0 0;
			li {
				margin-right: 10px;
				&:first-child {
					float: left;
				}
				&:nth-child(2) {
					float: right;
					margin-right: 0;
				}
				
			}
		}
	}
}
a.btn_filt_map {
	@include border-radius(3px);
    padding: 8px 12px 8px 10px;
	line-height: 1;
    margin-bottom: 5px;
	display: inline-block;
	border: 1px solid rgba(0,0,0,0.08);
	font-weight: 600;
	@include fontSize(12px);
	color: rgba(0,0,0,.5);
		position: relative;
	@include mobile {
		text-indent: -999px;
		display: block;
		width: 32px;
		height: 32px;
		padding: 0;
	}
	&:hover{
		color: $color-1;
	}
	&:before{
		font-family: 'ElegantIcons';
		content: "\67";
		display: inline-block;
		margin-right: 5px;
		@include fontSize(13px);
		@include mobile {
			text-indent: 1px;
			position: absolute;
			left: 3px;
			top:4px;
			@include fontSize(21px);
			font-weight: normal;
		}
	}
}

#filters {
	background-color: #fff;
	padding-top: 15px;
	border-bottom: 1px solid $border-color-2;
	h6 {
		margin-bottom: 15px;
		@include fontSize(16px);
	}
}

.map_view#filters {
	margin: 0 -15px;
}


.box_list.map_view {
    min-height: 120px;
    margin: 0 0 20px 0;

    .wrapper {
        min-height: 125px;
        padding: 20px 20px 0 20px;

        .price {
            line-height: 1.2;
        }

        h3 {
            @include mobile {
                @include fontSize(16px);
            }
        }

        @include tablet {
            min-height: 110px;
            padding-right: 45px;
        }

        @include tablet {
            min-height: 110px;
        }
    }

    figure {
        height: 185px;
        min-height: inherit;

        small {
            @include fontSize(11px);
        }

        @include tablet {
            height: 165px;
        }

        a {
            img {
                height: 210px;
                width: auto;
                max-width: none;
                @include transition-default;

                @include tablet {
                    max-width: inherit;
                    height: 210px;
                    width: auto;
                }
            }

            &:hover img {
                @include transform (translate(-50%, -50%) scale(1));
            }
        }

    }

    ul {
        padding: 20px 15px 0 15px;

        li:first-child {
            @include tablet {
                display: none;
            }
        }
    }
}

a.address {
	display: inline-block;
	font-weight: 500;
	color: #999;
	line-height: 1;
	@include fontSize(13px);
	&:before {
		font-family: 'ElegantIcons';
		content: "\e081";
		margin-right: 5px;
		display: inline-block;
	}
	&:hover {
		color: $color-1;
	}
}

.distance {
	margin-bottom: 15px;
	span {
		font-weight: 600;
		color:$color-1;
	}
}
.rangeslider__handle {
  border: 2px solid $color-1!important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
}
.rangeslider__fill {
  background: $color-1!important;
}

/*-------- 2.15 Autocomplete Address Search --------*/
.pac-container {
  margin-top: 5px;
  border-top:none!important;
  @include border-radius(4px!important);
  font-family: $font-main;
  color: $body-text-color;
  @include box-shadow (0 12px 35px 2px rgba(0, 0, 0, 0.12));
}
.pac-item-query {
    color: $body-text-color;
}

/*-------- 2.16 OpenStreet Map --------*/
@import "leaflet.scss";

/*-------- 2.17 Parallax Video Background --------*/
.hero_single.jarallax {
    height: 650px;
    .wrapper {
        h3 {
            @include fontSize(42px);
            text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
            @include mobile {
                @include fontSize(26px);
                margin-top: 60px;
            }
            @include extra-small-mobile {
                @include fontSize(22px);
            }
        }

        p {
            font-weight: 400;
            margin: 5px 0 0 0;
            padding: 0;
            @include fontSize(21px);
            text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
            @include mobile {
                padding: 0;
                @include fontSize(14px);
            }
        }
    }
    video {
	    opacity: 1;
	}
}

/*-------- 2.18 Other Headers --------*/
.hero_single {
    &.fullscreen {
        width: 100%;
        height: 100vh;
        @include mobile {height: 90vh;}
        &.video_bg {
            video {
                position: absolute;
                top: 0;
                left: 0;
                min-width: 100%;
                min-height: 100%;
                width: auto;
                height: auto;
                opacity: 1;
                z-index: -1;
            }
        }
    }
}
a.btn_explore{
    background: #fff;
	display: block;
	width: 60px;
	height: 60px;
	@include border-radius(50%); 
	line-height: 65px;
    text-align: center;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -30px;
	@include fontSize(24px);
	@include transition-default;
	&:hover {bottom:30px;}
	box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
	animation: pulseanime 2.5s infinite;
	-webkit-animation: pulseanime 2.5s infinite;
}

@-webkit-keyframes pulseanime {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}
@keyframes pulseanime {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}
/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/

/*-------- 3.1 Misc --------*/

@media (min-width: 1400px){
.container-custom  {
    max-width: 1600px;
    padding-left: 60px;
    padding-right:60px;
}
}
.opacity-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
}

.form-control {
	padding: 10px;
	height: 42px;
	@include fontSize(14px);
	@include border-radius(3px);
	border: 1px solid $border-color-3;
	color: #495057;
}
.form-group {
	position: relative;
	margin-bottom: 1rem;
}
.bg_color_1 {
	background-color: #fff;
}
.box_1 {
	background-color: $color-4;
	color: #fff;
	padding: 45px;
	opacity: 0;	
	p {opacity: 0.8;}
	h3 {color: #fff;}
}

.animated {
	.box_1 {
		animation: color 0.3s ease-in-out;
		@include animation-delay (0.3s);
		@include animation-fill (forwards);
		opacity: 0;
		@include backface-visibility (hidden);
	}
}

.call_section {
	background: url(../img/bg_call_section.jpg) center center no-repeat fixed;
   	@include background-size(cover);
	min-height: 400px;
	padding: 10% 0;
}

.box_faq {
	position: relative;
	padding-left: 50px;
	h4 {
		@include fontSize(18px);
	}
	i {
		@include fontSize(36px);
		position: absolute;
		left: 0;
		top: 0;
		color: #999;
	}
}

.custom-select-form {
	.nice-select {
		@include border-radius(3px);
		border: 1px solid $border-color-3;
		height: 45px;
		line-height: 42px;
		&:hover {
			border-color: $border-color-3;
		}
		&:active,
		&:focus {
			border-color: #80bdff;
			outline: 0;
			box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}
	}
	select {
		display: none;
	}
}

ul.share-buttons {
	padding: 0;
	list-style: none;
	text-align: center;
	margin: 0 0 25px 0;
	li {
		display: inline-block;
		margin: 0 5px 5px 5px;
		 a {
			background: #fff;
			border: 1px solid #666;
			border-radius: 50px;
			font-weight: 500;
			font-size: 13px;
			padding: 7px 20px;
			transition: 0.3s;
			display: inline-block;
			line-height: 17px;
			font-weight: 500;
			&:hover {color: #fff;}
			&.fb-share {
				border-color: #3b5998;
				color: #3b5998;
				&:hover {background: #3b5998;color: #fff;}
			}
			&.gplus-share {
				border-color: #dd4b39;
				color: #dd4b39;
				&:hover {background: #dd4b39;color: #fff;}
			}
			&.twitter-share {
				border-color: #1da1f2;
				color: #1da1f2;
				&:hover {background: #1da1f2;color: #fff;}
			}
		}
		i {
			@include fontSize(16px);
			position: relative;
			right: 3px;
			top: 2px;
		}
	}
}

/* Jquery select */
// Default variables
$font_size: 14px !default;
$font_size_small: 12px !default;

$input_border_radius: 3px !default;
$input_height: 50px !default;
$input_height_small: 36px !default;
$dropdown_padding: 15px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #999 !default;
$gray_lighter: #f6f6f6 !default;
$primary_light: $gray !default;
$arrow_color: $gray_light !default;

// Style the dropdown
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: $input_border_radius;
  border: none; 
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: $font_size;
  font-weight: normal;
  height: $input_height;
  line-height: $input_height - 2;
  outline: none;
  padding-left: $dropdown_padding;
  padding-right: $dropdown_padding + 12;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto;
  color: $body-text-color;
  padding-top: 2px;
  &:hover {
    border-color: darken($gray_light, 5%);
  }
  &:active, &:focus {
    border-color: $primary_light;
  }
  // Arrow
  &:after { 
    border-bottom: 2px solid $arrow_color;
    border-right: 2px solid $arrow_color;
    content: '';
    display: block; 
    height: 8px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute; 
    right: 20px; 
    top: 50%; 
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 8px;
  }
  
  &.open {
    @extend :active;
    &:after {
      transform: rotate(-135deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }
  &.disabled {
    border-color: lighten($gray_light, 2%);
    color: $gray;
    pointer-events: none;
    &:after { 
      border-color: lighten($arrow_color, 20%);
    }
  }
  
  // Modifiers
  &.wide {
    width: 100%;
    .list {
      left: 0 !important;
      right: 0 !important;
    }
  }
  &.right {
    float: right;
    .list {
      left: auto;
      right: 0;
    }
  }
  &.small {
    font-size: $font_size_small;
    height: $input_height_small;
    line-height: $input_height_small - 2;
    &:after { 
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: $input_height_small - 2;
      min-height: $input_height_small - 2;
    }
  }
  
  // List and options
  .list {
    background-color: #fff;
    border-radius: $input_border_radius;
    box-shadow: 0 0 0 1px rgba($gray_dark, .11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%; left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(- $input_height / 2);
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9;
    &:hover .option:not(:hover) {
      background-color: transparent !important;
    }
	// for overflow fix. from here.
    height: 18vh;
    overflow: auto;
    
    // for custom scrollbar inside select box
    &::-webkit-scrollbar{
      width: 14px;
      height: 18px;
    }
    &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.15);
      -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    }
    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent
    }
  }
  .option {
    cursor: pointer;
    font-weight: 400;
    line-height: $input_height - 12;
    list-style: none;
    min-height: $input_height - 12;
    outline: none;
    padding-left: $dropdown_padding;
    padding-right: $dropdown_padding + 11;
    text-align: left;
    transition: all 0.2s;
    &:hover, &.focus, &.selected.focus { 
      background-color: $gray_lighter;
    }
    &.selected { 
      font-weight: 500;
    }
    &.disabled {
      background-color: transparent;
      color: $gray;
      cursor: default;
    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }
  &.open {
    .list {
      display: block;
    }
  }
}

/* Scroll top button */
#toTop {
	position: fixed;
	right: 25px;
	bottom: 25px;
	z-index: 9999;
	width: 46px;
	height: 46px;
	background-color: rgba(0,0,0,.6);
	opacity: 1;
    border-radius: 50%;
    text-align: center;
    font-size: 21px;
	color: #fff;
	cursor: pointer;
	&:after {
		content: "\e899";
		font-family: "fontello";
		position: relative;
		display: block;
		top: 50%;
		-webkit-transform: translateY(-55%);
		transform: translateY(-55%);
	}
	&:hover {
		background-color: $color-1;
	}
}

/* Bootstrap Modal Fix */
.modal-backdrop {
    position: static;
    background-color: transparent;
}

.modal {
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.7);
}

/*-------- 3.2 Accordion --------*/
.accordion_2 {
	.card {
		border: 0;
		margin-bottom: 5px;
		@include box-shadow (0px 0px 15px 0px rgba(0, 0, 0, 0.05));
		.card-header {
			background-color: #fff;
			border-bottom: 1px solid $border-color-2;
			padding: 20px;
		}
	}
	.card-body {
		padding-bottom: 0;
	}
	.card-header h5 {
		@include fontSize(16px);
		a {
			border: 0;
			display: block;
			color: #999;
			i.indicator {
				@include fontSize(18px);
				font-weight: normal;
				float: right;
				color: $color-1;
			}
		}
	}
}
.card {
    border-radius: 0;
	border: 0;
	margin-bottom: 5px;
}

/*-------- 3.3 List --------*/
ul.list_ok {
	list-style: none;
	margin: 25px 0 30px 0;
	padding: 0;
	li {
		position: relative;
		padding-left: 45px;
		margin-bottom: 20px;
		&:before {
			font-family: 'ElegantIcons';
			content: "\52";
			position: absolute;
			left: 0;
			top: -10px;
			@include fontSize(32px);
			color: #8dc63f;
		}
	}
}

ul.bullets {
	line-height: $line-height-large;
	margin: 0;
	padding: 0;
	li {
		position: relative;
		padding-left: 20px;
		&:before {
			font-style: normal;
			font-weight: normal;
			font-family: 'ElegantIcons';
			@include fontSize(14px);
			content: "\5c";
			color: #ccc;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

/*-------- 3.4 Spacing --------*/
.add_bottom_15 {
    margin-bottom: 15px;
}
.add_bottom_30 {
    margin-bottom: 30px;
}
.add_bottom_45 {
    margin-bottom: 45px;
}
.add_bottom_60 {
    margin-bottom: 60px;
}
.add_bottom_75 {
    margin-bottom: 75px;
}
.add_top_10 {
    margin-top: 10px;
}
.add_top_15 {
    margin-top: 15px;
}
.add_top_20 {
    margin-top: 20px;
}
.add_top_30 {
    margin-top: 30px;
}
.add_top_60 {
    margin-top: 60px;
}
.more_padding_left {
    padding-left: 40px;
}
.nomargin_top {
    margin-top: 0;
}
.nopadding {
    margin: 0 !important;
    padding: 0 !important;
}
.nomargin {
    margin: 0 !important;
}
.margin_60 {
	padding-top: 60px;
	padding-bottom: 60px;
}
.margin_60_35 {
	padding-top: 60px;
	padding-bottom: 35px;
	@include extra-small-mobile {padding-top: 30px; padding-bottom: 5px;}
}
.margin_80 {
	padding-top: 80px;
	padding-bottom: 80px;
	@include tablet {padding-bottom: 60px; padding-top: 60px;}
	@include extra-small-mobile {padding-top: 30px; padding-bottom: 30px;}
}
.margin_80_55 {
	padding-top: 80px;
	padding-bottom: 55px;
	@include tablet {padding-top: 60px; padding-bottom: 35px;}
	@include extra-small-mobile {padding-top: 45px; padding-bottom: 15px;}
}
.margin_80_0 {
	padding-top: 80px;
	@include tablet {padding-top: 60px;}
	@include extra-small-mobile {padding-top:45px;}
}
.margin_60_0 {
	padding-top: 60px;
	@include tablet {padding-top: 60px;}
	@include extra-small-mobile {padding-top:45px;}
}
.margin_30_95 {
	padding-top: 30px;
	padding-bottom: 95px;
	@include tablet {padding-top: 15px;padding-bottom: 35px;}
	@include extra-small-mobile {padding-top: 5px; padding-bottom: 30px;}
}
.hidden_tablet{
	@include tablet {display: none!important;}
}
.hidden_mobile {
	@include mobile {display: none!important;}
}

/*-------- 3.5 Block reveal --------*/
.animated .block-reveal {
	height: auto;
	overflow: hidden;
	position: relative;
	width: 100%;
}
.block-horizzontal,.block-vertical {
	height: 100%;
	position: absolute;
	width: 100%;
	z-index: 10;
}
.animated {
	.block-horizzontal {
		animation: reveal_left_to_right 1s ease-in-out;
		@include animation-fill (forwards);
		background: $color-2;
	}
	.block-vertical {
		animation: reveal_top_to_bottom 1s ease-in-out;
		@include animation-fill (forwards);
		background: $color-2;
	}
}

.block-reveal img{
	@include transition-default;
	opacity: 0;
}
.animated .block-reveal img {
	animation: color 0.5s ease-in-out;
    @include animation-delay (0.5s);
    @include animation-fill (forwards);
    opacity: 0;
	@include backface-visibility (hidden);
}

@keyframes reveal_left_to_right {
	0% {
		@include transform (translateX(-100%));
	}
	50% {
		@include transform (translateX(0));
	}
	100% {
		@include transform (translateX(100%));
	}
}

@keyframes reveal_top_to_bottom {
	0% {
		@include transform (translateY(-100%));
	}
	50% {
		@include transform (translateY(0));
	}
	100% {
		@include transform (translateY(100%));
	}
}

@keyframes color {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/*-------- 3.6 Cookie bar --------*/
#cookie-bar {
	background: rgba(0, 0, 0, 0.8);
	height: auto;
	line-height: 24px;
	color: #eeeeee;
	text-align: center;
	padding: 15px 0;
	font-weight: 400;
	@include fontSize(13px);
	font-weight: 500;
	p {
		margin: 0;
		padding: 0;
	}
	a {
		color: #fff;
		display: inline-block;
		@include border-radius(3px);
		text-decoration: none;
		padding: 2px 10px;
		margin-left: 8px;
		@include mobile {
			display: block;
			max-width: 150px;
			margin: auto;
			margin-bottom: 5px;
		}
	}
	.cb-enable {
		background: $color-3;
		&:hover {
			background: #fff;
			color: $color-2;
		}
	}
	.cb-disable {
		background: #990000;
		&:hover {
			background: #bb0000;
		}
	}
	.cb-policy {
		background: $color-1;
		&:hover {
			background: #fff;
			color: $color-2;
		}
	}
	&.fixed {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999999;
		&.bottom {
			bottom: 0;
			top: auto;
		}
	}
	@include mobile {
		padding: 5px 0;
	}
}


/*-------- 3.7 Sing In Modal --------*/
#sign-in-dialog {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  text-align: left;
  max-width: 400px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  @include border-radius(4px);
}

/* Popup close button*/
#sign-in-dialog .mfp-close,
#lang-dialog .mfp-close {
  color: #666;
  background-color: #e4e4e4;
  @include border-radius(50%);
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
#sign-in-dialog .mfp-close:hover,
#lang-dialog .mfp-close:hover {
  color: #fff;
  background-color: #66676b;
}

#sign-in-dialog .mfp-close:before{
  font-size: 24px;
  font-family: 'ElegantIcons';
  content: "\4d";
}
.small-dialog-header {
  font-size: 18px;
  width: calc(100% + 60px);
  position: relative;
  left: -30px;
  top: 0;
  @include border-radius(4px 4px);
  display: inline-block;
  background-color: #f6f6f6;
  padding: 18px 20px 15px 20px;
  margin-bottom: 30px;
  h3 {
	  padding: 0;
	  margin: 0;
	  @include fontSize(18px);
	  font-weight: 500;
	}
}
#sign-in-dialog .form-group {
  position: relative;
}
#sign-in-dialog .form-group input.form-control {
  padding-left: 40px;
}
#sign-in-dialog .form-group i {
  @include fontSize(21px);
  position: absolute;
  left: 12px;
  top: 34px;
  color: #ccc;
  width: 25px;
  height: 25px;
  display: block;
  font-weight: 400!important;
}
#sign-in-dialog .form-group i.icon_lock_alt {
  top: 36px;
}

.my-toggle {
  background: transparent;
  border: 0;
  @include border-radius(3px);
  color: #888;
  cursor: pointer;
  font-size: 10px;
  @include fontSize(10px);
  font-weight: bold;
  margin-right: 5px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
	&:hover,&:focus {
	  background-color: #eee;
	  color: #555;
	  outline: transparent;
	}
}

.hideShowPassword-wrapper{
  width: 100%!important;
}

.checkboxes label{
  color: #999;
}
a#forgot{
  color: #999;
  font-weight: 500;
	@include fontSize(13px);
}

#forgot_pw {
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  min-height: 450px;
  display: none;
  label {
	  font-weight: 500;
	}
}

.sign-in-wrapper {
  position: relative;
  height: 100%;
}

a.social_bt {
  @include border-radius(3px);
  text-align: center;
  color: #fff;
  min-width: 200px;
  margin-bottom: 15px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
	  -webkit-filter: brightness(115%);
	  filter: brightness(115%);
	}
}
a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
	font-family: 'ElegantIcons';
	position: absolute;
	left: 12px;
	top: 10px;
	font-size: 1rem;
	color: #fff;
}

a.social_bt.facebook {
	background-color: #3B5998;
	&:before {
		content: "\e093";
	}
}

a.social_bt.google {
	background-color: #DC4E41;
	&:before {
		content: "\e096";
		top: 12px;
	}
}

a#forgot{
	position: relative;
	top:-3px;
}

.divider {
	text-align: center;
	height: 1px;
	margin: 30px 0 20px 0;
	background-color: #e1e8ed;
	span {
		position: relative;
		top: -20px;
		background-color: #fff;
		display: inline-block;
		padding: 10px;
		font-style: italic;
	}
}

/* Magnific popup */
.mfp-bottom-bar {
    padding: 0 10px!important;
}
.mfp-counter {
    right: 10px!important;
	color: #fff!important;
}
.mfp-bg {
    opacity: 0.8!important;
}

/*-------- 3.8 Input Switcher --------*/
.switchery {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative;
  vertical-align: middle;
  width: 50px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;
  background-clip: content-box;
  float:right;
  > small {
	  background: #fff;
	  border-radius: 100%;
	  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
	  height: 30px;
	  position: absolute;
	  top: 0;
	  width: 30px;
	}
}

/* Switchery sizes. */
.switchery-small {
	border-radius: 20px;
	height: 20px;
	width: 33px;
	>small {
		height: 20px;
		width: 20px;
	}
}
.switchery-large {
	border-radius: 40px;
	height: 40px;
	width: 66px;
	>small {
		height: 40px;
		width: 40px;
	}
}

/*-------- 3.9 Datepicker --------*/
.daterangepicker {
  font-family: $font-main!important;
}
/* Fix for scroll issue */
@media screen and (min-width: 560px) {
	.daterangepicker {
		width: 490px!important;
	}
	.daterangepicker.single {
		width:auto!important;
	}
}

.applyBtn.btn.btn-sm.btn-primary {
	background-color: $color-1;
	border: 0;
}

.drp-selected {
	display: none!important;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $color-2!important;
}

@media screen and (max-width: 600px) {
	.daterangepicker .drp-calendar  {
		max-width: 100%!important;
	}
	.daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right  {
	    clear: none!important;
	    float: none!important;
	    margin-right: 0;
	}
}

.daterangepicker {
    @include box-shadow (0 12px 35px 2px rgba(0, 0, 0, 0.12));
}

/*-------- 3.10 Modal Generic --------*/
#modal-dialog {
  background: #fff;
  padding: 30px;
  padding-top: 0;
  text-align: left;
  max-width: 450px;
  margin: 40px auto;
  position: relative;
  box-sizing: border-box;
  @include border-radius(4px);
}

/* Popup close button*/
#modal-dialog .mfp-close{
  color: #666;
  background-color: #e4e4e4;
  @include border-radius(50%);
  top: 12px;
  right: 20px;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
#modal-dialog .mfp-close:hover{
  color: #fff;
  background-color: #66676b;
}

#modal-dialog .mfp-close:before{
  font-size: 24px;
  font-family: 'ElegantIcons';
  content: "\4d";
}

/*-------- 3.11 Others pricing tables --------*/
.margin_tabs {
  margin-top:-97px;
  @include tablet {margin-top:-68px;}
  @include mobile {margin-top:-50px;}
}
.tabs {
  position: relative;
  width: 100%;
  overflow: visible;
  padding-bottom: 35px;
  z-index: 5;
  nav {
    text-align: center;
    overflow: hidden;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: inline-block;
      width: 100%;
            text-align: center;
      li {
        margin: 0 -4px 0 0;
        display: block;
        display: inline-block;
        background-color: #ededed;
        width: 33%;
        text-align: left;
        line-height: 1.3;
        @include tablet {text-align: center;}
        a {
          padding: 25px 35px 25px 85px;
          color: #555;
          display: block;
          @include fontSize(21px);
          white-space: nowrap;
          position: relative;
          font-weight: 500;
          @include tablet {padding: 15px; @include fontSize(16px);}
          i {
            position: absolute;
            left: 35px;
            top:25px;
            @include fontSize(38px);
            @include tablet {display: none;}
          }
          em {
            display: block;
            @include fontSize(16px);
            font-style: normal;
            font-weight: normal;
            @include tablet {@include fontSize(14px);}
            @include mobile {display: none;}
          }
        }
        &:first-child {
          @include border-radius(3px 0 0 0);
            border-right: 2px solid #ddd;
        }
        &:last-child {
          @include border-radius(0 3px 0 0);
          border-left: 2px solid #ddd;
        }
        &.tab-current {
          background-color: #fafafc;
          z-index: 100;
          &:after {
            right: auto;
            left: 100%;
            width: 4000px;
          }
          a {
            color: #222;
          }
          a i {
            color: $color-1;
          }
        }
      }
    }
  }
}

/* Content */
.content {
  section {
    padding: 30px 0 0 0;
    display: none;
    max-width: 1230px;
    margin: 0 auto;
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &.content-current {
  display: block;
}
  }
}

.content section:after {
  clear: both;
}

.no-js .content section {
  display: block;
  padding-bottom: 2em;
  border-bottom: 1px solid #e2e2e2;
}


.box_pricing {
  padding: 30px;
  margin-bottom: 30px;
  border:1px solid $border-color-3;
  @include border-radius(3px);
  position: relative;
  @include mobile {padding: 15px;}
  hr {
    margin: 25px -30px;
    border-color:$border-color-3;
    @include mobile {margin: 25px -15px;}
  }
  ul {
    list-style: disc;
    padding:0 0 0 20px;
    margin: 0 0 30px 0;
  }
  a {
    background-color: $color-1;
    display: block;
    color: #fff;
    padding: 10px;
    font-weight: 500;
    @include border-radius(3px);
    text-align: center;
    &:hover{
      background-color:$color-2;
    }
  }
  .price {
    text-align: center;
    font-weight: 500;
    @include fontSize(36px);
    margin-bottom: 20px;
    color: $color-1;
    sup {
      @include fontSize(16px);
    }
    em {
      @include fontSize(16px);
      color: $body-text-color;
      font-weight: 400;
    }
  }
}

.ribbon_tabs {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    span {
        font-size: 10px;
        font-weight: bold;
        color: #222;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 100px;
        display: block;
        position: absolute;
        top: 19px;
        right: -21px;
        &.top_selling {
            background: #00dfaa;
            &::before {
                content: "";
                position: absolute;
                left: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid #008a69;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #008a69;
            }
            &::after {
                content: "";
                position: absolute;
                right: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid #008a69;
                border-bottom: 3px solid transparent;
                border-top: 3px solid #008a69;
            }
        }
    }
}

/*Pricing tables*/
.plans {
  margin: 0px auto 20px;
}
.plans:before,
.plans:after {
  content: '';
  display: table;
}
.plans:after {
  clear: both;
}
.plan {
  margin: 10px 0;
  padding: 20px 20px 30px 20px;
  text-align: center;
  border: solid $border-color-3;
  border-width: 1px 0 1px 1px;
  background-color: #fff;
  @include box-shadow (0 2px 4px rgba(3,27,78,.06));
  @include border-radius(5px);
  overflow: hidden;
  @include tablet {border-width: 1px;}
}
.col-md-4.plan:first-child {
  margin-right: -5px;
  margin-left: 5px;
  @include tablet {margin: 0 0 15px 0;}
}
.plan:last-child {
  border-width: 1px;
}
.plan-title {
  position: relative;
  margin: -25px -25px 30px -25px;
  padding: 30px 20px 1px 20px;
  border-bottom: 1px dotted $border-color-3;
  @include fontSize(15px);
  background-color: #f9f9f9;
  line-height: 1.2;
  color:#777;
  h3 {
    margin-bottom: 0;
  }
}
.plan-tall .plan-title {
  background-color: $color-1;
  border-bottom: none;
  color: #fff;
   h3 {
    color: #fff;
  }
}

.plan-price {
  margin: 0 auto 15px;
  width: 110px;
  height: 110px;
  line-height: 115px;
  font-size: 30px;
  font-weight: 600;
  color: white;
  background: #777;
  @include border-radius(100px);
  display: inline-block;
  text-align: center;
}
.plan-price > span {
  font-size: 12px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.9);
}
.plan-features {
  margin-bottom: 20px;
  line-height: 2;
}
ul.plan-features {
  padding: 0;
  margin: 0 0 20px 0;
}
.plan-features li {
  list-style: none;
}
.plan-tall {
  margin: 0;
  background: #fff;
  border-radius: 10px;
  margin-right: -5px;
  position: relative;
  z-index: 100;
  border: solid $color-1;
  position: relative;
}
.plan-tall > .plan-price {
  height: 130px;
  width: 130px;
  line-height: 135px;
  font-size: 36px;
  background: $color-1;
  @include border-radius(130px);
}

.plan-tall + .plan {
  border-left: 0;
  @include tablet {border-left: 1px solid $border-color-3;}
}

/*-------- 3.12 Styles Switcher --------*/
#style-switcher {
	background: #fff;
	width: 205px;
	position: fixed;
	top: 205px;
	z-index: 99;
	left: -205px;
	border-radius: 0 0 4px 0;
	-webkit-box-shadow: 0 6px 15px rgba(0, 0, 0, .175);
	box-shadow: 0 6px 15px rgba(0, 0, 0, .175);
	h3 {
		color: #fff;
		font-size: 13px;
		margin: 10px 0;
		font-weight: 500;
		text-transform: none;
		letter-spacing: 0;
	}
	ul {
		list-style: none;
		padding: 0;
		margin-bottom: 22px;
	}
	div {
		padding: 6px 18px;
	}
	h6 {
		background: #f8f8f8;
		color: #333;
		font-size: 14px;
		line-height: 46px;
		padding: 0 0 0 20px;
		margin-top: 0;
		font-weight: 500;
		a {
			background-color: #f8f8f8;
			display: block;
			height: 46px;
			position: absolute;
			right: -46px;
			top: 0;
			width: 46px;
			border-radius: 0 4px 4px 0;
			font-size: 22px;
			color: #fff;
			text-align: center;
			i {
				position: relative;
				top: 4px;
				color: #333;
			}
		}
	}
}

	.colors {
		list-style: none;
		margin: 0 0px 10px 0;
		overflow: hidden;
	}
	
	.colors li {
		float: left;
		margin: 4px 2px 0 2px;
	}
	
	.colors li a {
		display: block;
		width: 17px;
		height: 17px;
		cursor: pointer;
	}
	
	.default {
		background: $color-1;
	}
	
	.aqua {
		background: #1cbbb4;
	}
	
	.green_switcher {
		background: #82ca9c;
	}
	
	.orange {
		background: #f7941d;
	}
	
	.blue {
		background: #004dda;
	}
	
	.colors li a.active {
		box-shadow: 0 0 0 2px #fff inset
	}
	
	.colors li a {
		border-radius: 3px;
	}
	
	@media only screen and (max-width: 1024px) {
		#style-switcher {
			display: none;
		}
	}